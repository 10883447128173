// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthModal_authModal__top__AjVIH {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AuthModal_authModal__langBtn__ZzwP8 {
  width: 100%;
  padding: 16px 8px 16px 32px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #EEE;
  margin-bottom: 8px;
}
.AuthModal_authModal__langBtn__ZzwP8:last-child {
  margin-bottom: 0;
}
.AuthModal_authModal__langBtn__ZzwP8 img {
  max-width: 38px;
  border-radius: 4px;
}
.AuthModal_authModal__langBtn__ZzwP8 span {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000;
  display: block;
  margin-left: 16px;
}
.AuthModal_authModal__langBtn__ZzwP8 svg {
  margin-left: auto;
}
.AuthModal_authModal__resendBtn__uow7O {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #457CFA;
  margin-bottom: 4px;
}
.AuthModal_authModal__resendBtn__uow7O:hover {
  text-decoration: none;
}
.AuthModal_authModal__codeEnd__4MYI0 {
  font-size: 14px;
  color: #fd3939;
  line-height: 12px;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.AuthModal_authModal__codeEnd__4MYI0 span {
  margin-right: 6px;
  margin-bottom: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/AuthModal/AuthModal.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAGE;EACE,WAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAEI;EACE,gBAAA;AAAN;AAGI;EACE,eAAA;EACA,kBAAA;AADN;AAII;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;AAFN;AAKI;EACE,iBAAA;AAHN;AAQE;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;EACA,kBAAA;AANJ;AAQI;EACE,qBAAA;AANN;AAUE;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AARJ;AASI;EACE,iBAAA;EACA,kBAAA;AAPN","sourcesContent":[".authModal {\n  &__top {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n\n  &__langBtn {\n    width: 100%;\n    padding: 16px 8px 16px 32px;\n    display: flex;\n    align-items: center;\n    border-radius: 12px;\n    background: #EEE;\n    margin-bottom: 8px;\n    &:last-child {\n      margin-bottom: 0;\n    }\n\n    img {\n      max-width: 38px;\n      border-radius: 4px;\n    }\n\n    span {\n      font-size: 13px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 20px;\n      color: #000;\n      display: block;\n      margin-left: 16px;\n    }\n\n    svg {\n      margin-left: auto;\n    }\n  }\n\n\n  &__resendBtn {\n    text-decoration: underline;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: -0.02em;\n    color: #457CFA;\n    margin-bottom: 4px;\n    // height: 40px;\n    &:hover {\n      text-decoration: none;\n    }\n  }\n\n  &__codeEnd {\n    font-size: 14px;\n    color: #fd3939;\n    line-height: 12px;\n    text-align: left;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    span {\n      margin-right: 6px;\n      margin-bottom: 4px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authModal__top": `AuthModal_authModal__top__AjVIH`,
	"authModal__langBtn": `AuthModal_authModal__langBtn__ZzwP8`,
	"authModal__resendBtn": `AuthModal_authModal__resendBtn__uow7O`,
	"authModal__codeEnd": `AuthModal_authModal__codeEnd__4MYI0`
};
export default ___CSS_LOADER_EXPORT___;
