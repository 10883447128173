import React, { useEffect, useRef, useState } from "react"
import styles from "./ServicesList.module.scss"
import { BACKEND_API_URL } from "../../utils/constants"
import { Link } from "react-router-dom"
import { selectAuth } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import LocalizedLink from "../../hoc/LocalizedLink"

interface Props {
  size?: string
  title?: any
  subtitle?: any
  background?: any
  img?: string
  id: string
}

const ServiceEl: React.FC<Props> = ({ size, title, subtitle, background, img, id }) => {
  const user = useAppSelector(selectAuth)
  // const subtitleRef = useRef<HTMLElement>(null)
  // const [heightOfBlock, setHeightOfBlock] = useState(0)
  // const [countLines, setCountLines] = useState(0)

  // useEffect(() => {
  //   if (subtitleRef.current && subtitleRef.current.lastChild?.textContent?.length && subtitleRef.current.lastChild?.textContent === subtitle) {
  //     // console.log(subtitleRef.current.lastChild?.textContent === subtitle);
  //     const blockWidth = subtitleRef.current.clientWidth
  //     const length = subtitleRef.current.lastChild?.textContent?.length
  //     const midleWidthLetter = 9.5
  //     const quntitiLettersInLine = Math.round(blockWidth / midleWidthLetter)

  //     // setCountLines(Math.ceil(length / quntitiLettersInLine))
  //     setCountLines((prev: any) => {
  //       console.log(Math.ceil(length / quntitiLettersInLine));
  //       return Math.ceil(length / quntitiLettersInLine)
  //     })

  //     if (window.document.documentElement.clientWidth < 640) {
  //       setHeightOfBlock(96)
  //     } else setHeightOfBlock(120)

  //     window.addEventListener("resize", () => {
  //       if (window.document.documentElement.clientWidth < 640) {
  //         setHeightOfBlock(96)
  //       } else setHeightOfBlock(120)
  //     })
  //   }
  // }, [subtitleRef])

  return (
    <LocalizedLink
      to={`/products/${id}`}
      style={{ background: background }}
      className={`${styles.serviceEl} ${size === "wide" ? styles.serviceEl__big : ""}`}
      onClick={(event: any) => {
        if (!user.token) {
          event.preventDefault()
        }
      }}
    >
      <p>
        {title} <br />
        <span style={{ height: "0px", display: "block" }} />
        <span
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
      </p>
      {/* <div dangerouslySetInnerHTML={{ __html: subtitle }}></div> */}
      {img && <img src={img ? BACKEND_API_URL + "/public/photo/" + img : ""} alt="" />}
    </LocalizedLink>
  )
}

export default ServiceEl
