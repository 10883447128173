import React, { useEffect, useState } from "react"
import styles from "./ProductItem.module.scss"
import { useTranslation } from "react-i18next"
import { BACKEND_API_URL } from "../../utils/constants"
import { declensionNumber, timeUnitsForLang } from "../../utils/helpers"

interface Props {
  img?: string
  text?: string
  firstTime?: string
  secondTime?: string
  timeUnit?: string
  ordered?: boolean
  onClick?: any
  isLoading?: any
  description?: string
}

const ProductItem: React.FC<Props> = ({
  img,
  text,
  firstTime,
  secondTime,
  ordered,
  onClick = () => null,
  timeUnit,
  isLoading = false,
  description,
}) => {
  const currentLang = localStorage.getItem("i18nextLng") || 'en'
  const [currentTimeUnit, setCurrentTimeUnit] = useState('')

  // let currentTimeUnit =
  //   timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][timeUnit as "minutes" | "hours" | "days"] 

  useEffect(()=> {
    setCurrentTimeUnit((prev: any) => {
      if(timeUnit && currentLang){
        return timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][timeUnit as "minutes" | "hours" | "days"] 
      } else return timeUnitsForLang['en']['minutes']
    })
  }, [timeUnit])

  useEffect(()=> {
    if (currentLang === "ru") {
      if (timeUnit === "hours") {
        if (firstTime && secondTime) {
          setCurrentTimeUnit(() => {
            return declensionNumber(Number(secondTime), ["час", "часа", "часов"])
          }) 
        } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
          setCurrentTimeUnit(()=> {
            return declensionNumber(Number(secondTime), ["часа", "часов", "часов"])
          })
        }
      }
      if (timeUnit === "days") {
        if (firstTime && secondTime) {
          setCurrentTimeUnit(()=> {
            return declensionNumber(Number(secondTime), ["день", "дня", "дней"])
          })
        } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
          setCurrentTimeUnit(()=> {
            return declensionNumber(Number(secondTime), ["дня", "дней", "дней"])
          })
        }
      }
    }
  }, [currentLang, timeUnit, firstTime, secondTime])

  // if (currentLang === "ru") {
  //   if (timeUnit === "hours") {
  //     if (firstTime && secondTime) {
  //       currentTimeUnit = declensionNumber(Number(secondTime), ["час", "часа", "часов"])
  //     } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
  //       currentTimeUnit = declensionNumber(Number(secondTime), ["часа", "часов", "часов"])
  //     }
  //   }
  //   if (timeUnit === "days") {
  //     if (firstTime && secondTime) {
  //       currentTimeUnit = declensionNumber(Number(secondTime), ["день", "дня", "дней"])
  //     } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
  //       currentTimeUnit = declensionNumber(Number(secondTime), ["дня", "дней", "дней"])
  //     }
  //   }
  // }

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  return isLoading ? (
    <div className={styles.productItemNew}>
      <div className={`${styles.productItemNew__img} selectBack`} />
      <div className={`${styles.productItemNew__info}`}>
        <div className={`${styles.productItemNew__time} ${styles.productItemNew__timeSk} selectBack`} />
        <div className={`${styles.productItemNew__title} ${styles.productItemNew__titleSk} selectBack`} />
        <div className={`${styles.productItemNew__descr} ${styles.productItemNew__descrSk} selectBack`} />
      </div>
    </div>
  ) : (

    <button className={styles.productItemNew} type="button" onClick={onClick}>
      <div className={`${styles.productItemNew__img} ${!img && styles.productItem__img_empty}`}>
      <img
          src={img ? BACKEND_API_URL + "/public/photo/preview/" + img : "/img/productNoImg.svg"}
          alt=""
        />
      </div>
      <div className={`${styles.productItemNew__info}`}>
        <div className={`${styles.productItemNew__time}`} style={{ display: (!firstTime && !secondTime) ? "none" : "block"}}>
          {(() => {
              if (firstTime && secondTime) {
                return (
                  <>
                    {firstTime}-{secondTime} {currentTimeUnit}
                  </>
                )
              } else if (firstTime && !secondTime) {
                return (
                  <>
                    {t("from")} {firstTime} {currentTimeUnit}
                  </>
                )
              } else if (!firstTime && secondTime) {
                return (
                  <>
                    {t("to")} {secondTime} {currentTimeUnit}
                  </>
                )
              }
            })()}
        </div>
        <div className={`${styles.productItemNew__title}`}>
        {text}
        </div>
        <div className={`${styles.productItemNew__descr}`}>
          {description ?? ""}
        </div>
      </div>
    </button>
    // <button className={styles.productItem} type="button" onClick={onClick}>
    //   {ordered && <div className={styles.productItem__ordered}>{t("ordered")}</div>}
    //   <div className={`${styles.productItem__img} ${!img && styles.productItem__img_empty}`}>
    //     <img
    //       src={img ? BACKEND_API_URL + "/public/photo/preview/" + img : "/img/productNoImg.svg"}
    //       alt=""
    //     />
    //     {firstTime || secondTime ? (
    //       <p>
    //         {(() => {
    //           if (!firstTime && !secondTime) {
    //             return ""
    //           } else if (firstTime && secondTime) {
    //             return (
    //               <>
    //                 {firstTime}-{secondTime} {currentTimeUnit}
    //               </>
    //             )
    //           } else if (firstTime && !secondTime) {
    //             return (
    //               <>
    //                 {t("from")} {firstTime} {currentTimeUnit}
    //               </>
    //             )
    //           } else if (!firstTime && secondTime) {
    //             return (
    //               <>
    //                 {t("to")} {secondTime} {currentTimeUnit}
    //               </>
    //             )
    //           }
    //         })()}
    //       </p>
    //     ) : (
    //       <></>
    //     )}
    //   </div>
    //   <div className={styles.productItem__text}>{text}</div>
    // </button>
  )
}

export default ProductItem
