import React from "react"
import styles from "./StarsRating.module.scss"
import StarRating from "./StarRating"

interface IStarsRating {
  name?: string
  changeRatingReview?: (value: any) => void
  disabled?: boolean
  rating?: number
}

const StarsRating: React.FC<IStarsRating> = ({ name = 'rating', changeRatingReview, disabled, rating }) => {
  const isChecked = (value: string): boolean => {
    if (rating) {
      return Number(value) === Number(rating) ? true : false
    } else return false
  }

  return (
    <div className={styles.stars}>
      <StarRating
        value="5"
        name={name}
        id={`${name}5`}
        changeRatingReview={(e: any) => changeRatingReview && changeRatingReview(e.target.value)}
        disabled={disabled}
        checkedFn={isChecked}
      />
      <StarRating
        value="4"
        name={name}
        id={`${name}4`}
        changeRatingReview={(e: any) => changeRatingReview && changeRatingReview(e.target.value)}
        disabled={disabled}
        checkedFn={isChecked}
      />
      <StarRating
        value="3"
        name={name}
        id={`${name}3`}
        changeRatingReview={(e: any) => changeRatingReview && changeRatingReview(e.target.value)}
        disabled={disabled}
        checkedFn={isChecked}
      />
      <StarRating
        value="2"
        name={name}
        id={`${name}2`}
        changeRatingReview={(e: any) => changeRatingReview && changeRatingReview(e.target.value)}
        disabled={disabled}
        checkedFn={isChecked}
      />
      <StarRating
        value="1"
        name={name}
        id={`${name}1`}
        changeRatingReview={(e: any) => changeRatingReview && changeRatingReview(e.target.value)}
        disabled={disabled}
        checkedFn={isChecked}
      />
    </div>
  )
}

export default StarsRating
