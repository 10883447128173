// import Head from 'next/head'
import MainLayout from "../components/MainLayout/MainLayout"

export default function Home() {
  return (
    <div className="wrapper">
      {/* <Head>
        <title>Staq Service</title>
        <meta name="description" content=""/>
        <link rel="icon" href="/favicon.ico"/>
      </Head> */}

      <main>
        <MainLayout />
      </main>
    </div>
  )
}
