// import Head from 'next/head'
import { Outlet } from "react-router-dom"
import RoomLayout from "../../components/RoomLayout/RoomLayout"

export default function Room() {
  return (
    <div className="wrapper">
      {/* <Head>
                <title>Staq Service</title>
                <meta name="description" content=""/>
                <link rel="icon" href="/favicon.ico"/>
            </Head> */}

      <main className="room-main">
        <RoomLayout>
          <Outlet />
        </RoomLayout>
      </main>
    </div>
  )
}
