// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleBack_titleBack__Q720D {
  display: flex;
  align-items: center;
  height: 48px;
}
.TitleBack_titleBack__back__TCojx {
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.TitleBack_titleBack__back__TCojx svg {
  fill: none;
}
.TitleBack_titleBack__text__pWqzO {
  margin-left: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #000;
  line-height: initial;
}
@media (min-width: 640px) {
  .TitleBack_titleBack__back__TCojx {
    width: 52px;
    height: 52px;
  }
  .TitleBack_titleBack__text__pWqzO {
    font-size: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/TitleBack/TitleBack.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,+CAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACJ;AACI;EACE,UAAA;AACN;AAGE;EACE,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;AADJ;AAIE;EACE;IACE,WAAA;IACA,YAAA;EAFJ;EAIE;IACE,eAAA;EAFJ;AACF","sourcesContent":[".titleBack {\n  display: flex;\n  align-items: center;\n  height: 48px;\n\n  &__back {\n    height: 36px;\n    width: 36px;\n    min-width: 36px;\n    border-radius: 50%;\n    background: #FFF;\n    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n\n    svg {\n      fill: none;\n    }\n  }\n\n  &__text {\n    margin-left: 12px;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    color: #000;\n    line-height: initial;\n  }\n\n  @media (min-width: 640px) {\n    &__back {\n      width: 52px;\n      height: 52px;\n    }\n    &__text {\n      font-size: 22px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleBack": `TitleBack_titleBack__Q720D`,
	"titleBack__back": `TitleBack_titleBack__back__TCojx`,
	"titleBack__text": `TitleBack_titleBack__text__pWqzO`
};
export default ___CSS_LOADER_EXPORT___;
