import React from "react"
import styles from "./TitleBack.module.scss"
import LocalizedLink from "../../hoc/LocalizedLink"
// import { useRouter } from 'next/router';

interface Props {
  title?: string
  searchVal?: string | number
  setSearchVal?: any
}

const TitleBack: React.FC<Props> = ({ title, setSearchVal, searchVal }) => {

  return (
    <div className={styles.titleBack}>
      <LocalizedLink
        className={styles.titleBack__back}
        to={"/"}
        onClick={() => {
          if (searchVal) setSearchVal("")
          // if (location.pathname !== "/") navigate("/")
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M15 6L9 12L15 18" stroke="#4B5058" strokeWidth="2" />
        </svg>
      </LocalizedLink>
      <div className={styles.titleBack__text}>{title}</div>
    </div>
  )
}

export default TitleBack
