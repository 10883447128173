// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footer__xvcPw {
  margin-top: auto;
}
.footer_footer__xvcPw .NoFindBtn {
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer_footer__wp__zTakh {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer_footer__xvcPw p {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.26px;
  opacity: 0.5;
  margin-bottom: 6px;
}
@media (min-width: 640px) {
  .footer_footer__xvcPw p {
    font-size: 22px;
    margin-bottom: 4px;
  }
  .footer_footer__wp__zTakh {
    padding-top: 80px;
  }
  .footer_footer__xvcPw .NoFindBtn {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEI;EACE,gBAAA;EACA,mBAAA;AAAN;AAIE;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFJ;AAIE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AAFJ;AAKE;EACE;IACE,eAAA;IACA,kBAAA;EAHJ;EAME;IACE,iBAAA;EAJJ;EAQI;IACE,gBAAA;IACA,mBAAA;EANN;AACF","sourcesContent":[".footer {\n  margin-top: auto;\n    \n  :global {\n    .NoFindBtn {\n      margin-top: 20px;\n      margin-bottom: 20px;\n    }\n  }\n\n  &__wp {\n    padding-top: 70px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  p {\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 14px;\n    letter-spacing: -0.26px;\n    opacity: 0.5;\n    margin-bottom: 6px;\n  }\n\n  @media (min-width: 640px) {\n    p {\n      font-size: 22px;\n      margin-bottom: 4px;\n    }\n\n    &__wp {\n      padding-top: 80px;\n    }\n\n    :global {\n      .NoFindBtn {\n        margin-top: 16px;\n        margin-bottom: 16px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__xvcPw`,
	"footer__wp": `footer_footer__wp__zTakh`
};
export default ___CSS_LOADER_EXPORT___;
