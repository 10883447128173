import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  openModal: false,
}

export const ratingModalSlice = createSlice({
  name: "ratingModal",
  initialState,
  reducers: {
    ratingModalOpen: (state: any, action: { payload: any; type: string }) => {
      action?.payload?.flag ? (state.openModal = action?.payload?.flag) : (state.openModal = !state.openModal)
    },
  },
})

export const { ratingModalOpen } = ratingModalSlice.actions
