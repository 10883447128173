import React, { useEffect, useState } from "react"

interface IStarRating {
  value: string
  name: string
  id: string
  checkedFn: (e: any) => boolean
  changeRatingReview: (e: any) => void
  disabled?: boolean
}

const StarRating: React.FC<IStarRating> = ({ value, name, id, checkedFn, changeRatingReview, disabled }) => {
  const [isChecked, setIsChecked]= useState<boolean>(false)

  useEffect(()=>{
    if(checkedFn){
      setIsChecked(checkedFn(value))
    } else setIsChecked(false)
  }, [])

  return (
    <>
      <input
        className="rating-star-input"
        type="radio"
        value={value}
        name={name}
        id={id}
        checked={isChecked ? isChecked : undefined}
        onChange={changeRatingReview}
        disabled={disabled}
      />
      <label className="rating-star-label" htmlFor={id} style={{pointerEvents: disabled ? 'none' : 'auto'}}>
        <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19.2527 2.37372C19.6837 1.43942 21.0116 1.43942 21.4425 2.37372L24.9976 10.0812C25.1733 10.462 25.5341 10.7242 25.9506 10.7736L34.3794 11.7729C35.4012 11.8941 35.8115 13.157 35.0561 13.8555L28.8244 19.6184C28.5166 19.9031 28.3787 20.3273 28.4605 20.7386L30.1146 29.0638C30.3152 30.073 29.2409 30.8535 28.3431 30.3509L20.9366 26.2051C20.5707 26.0002 20.1246 26.0002 19.7587 26.2051L12.3522 30.3509C11.4544 30.8535 10.3801 30.073 10.5806 29.0638L12.2348 20.7386C12.3165 20.3273 12.1787 19.9031 11.8708 19.6184L5.63916 13.8555C4.88375 13.157 5.29409 11.8941 6.31583 11.7729L14.7447 10.7736C15.1611 10.7242 15.522 10.462 15.6976 10.0812L19.2527 2.37372Z"
            fill="#E0E7EB"
          />
        </svg>
      </label>
    </>
  )
}

export default StarRating
