import React, { useMemo, useState } from "react"
import Modal from "../../Modal/Modal"
import styles from "./SelectObjModal.module.scss"
import Search from "../../Search/Search"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import { TechnicalObject } from "../../../types/orderTypes"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

interface Props {
  open: any
  setOpen: any
  techObjects: TechnicalObject[]
  setBackModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoading?: boolean
}

const SelectObjModal: React.FC<Props> = ({ open, setOpen, techObjects, setBackModal, isLoading }) => {
  const [searchVal, setSearchVal] = useState("")

  const { values, submitForm, setFieldValue } = useFormikContext<{ [key: string]: any }>()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const backStepOrder = () => {
    setOpen(false)
    setSearchVal("")
  }

  const nextStepOrder = () => {
    if (values.technical_object_id) {
      setBackModal(true)
      setOpen(false)
    }
  }

  const techObjectsList = useMemo(
    () => techObjects.filter(({ name }: any) => name?.toLowerCase().includes(String(searchVal).toLowerCase())),
    [searchVal, techObjects],
  )

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className={`modal-head`}>
        <div className={`modal-title`}>{t("selectObj")}</div>
      </div>
      <div className={`modal-text`}>{t("mustSelectObj")}</div>
      <div className={styles.selectObjModal__main}>
        <Search searchVal={searchVal} setSearchVal={setSearchVal} />
        <div className={styles.selectObjModal__list}>
          {techObjectsList.length ? (
            techObjectsList?.map(
              (el: TechnicalObject, kk: number) => (
                <RadioRow
                  key={kk}
                  name="name"
                  title={el.name}
                  onChange={() => setFieldValue("technical_object_id", el.id)}
                  value={el.id}
                  checked={values.technical_object_id === el.id}
                />
              ),
              // <div key={kk} className={`${styles.selectObjModal__item} ${selectObj === el.value ? styles.current : ""}`} onClick={()=>setSelectObj(el.value)}>
              //   <div className={styles.selectObjModal__itemIco}/>
              //   <div className={styles.selectObjModal__itemText}>{el.title}</div>
              // </div>
            )
          ) : (
            <p className={styles.nothingContent}>{t("searchServiceNotFound")}</p>
          )}
        </div>
      </div>
      <div className={`modal-bottom ${styles.selectObjModal__bottom}`}>
        <button type="button" onClick={backStepOrder} className={`btn btn_grey btn_sm`}>
          {t("back")}
        </button>
        <button type="button" disabled={isLoading} onClick={nextStepOrder} className={`btn`}>
          {t("next")}
        </button>
      </div>
    </Modal>
  )
}

export default SelectObjModal
