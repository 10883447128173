import React from 'react'
import styles from "./OrdersList.module.scss"

interface Props {
    isMainLoad?: boolean
}

const OrdersSkeleton:React.FC<Props> = ({isMainLoad}) => {
    return (
        <div className={styles.ordersList}>
            {isMainLoad && 
                <>
                    <div className={`selectBack ${styles.ordersList__headSk}`}></div>
                    <div className={`selectBack ${styles.ordersList__itemSk}`}></div>
                    <div className={`selectBack ${styles.ordersList__itemSk}`}></div>
                    <div className={`selectBack ${styles.ordersList__itemSk}`}></div>
                    <div className={`selectBack ${styles.ordersList__itemSk}`}></div>
                </>
            }
            <div className={`selectBack ${styles.ordersList__itemSk}`}></div>
            <div className={`selectBack ${styles.ordersList__itemSk}`}></div>
            <div className={`selectBack ${styles.ordersList__itemSk}`}></div>
        </div>
    )
}

export default OrdersSkeleton