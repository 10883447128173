import React, { useEffect } from "react"
import styles from "./RoomServicesList.module.scss"
import stylesEl from "../RoomServicesEl/RoomServicesEl.module.scss"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../hooks"
import moment from "moment"
import { langWithCurrentMomentCode } from "../../utils/helpers"
import { currentObject } from "../../redux/slice/object"
import RoomServicesEl from "../RoomServicesEl/RoomServicesEl"
import { servicesBackgroundList } from "../../utils/helpers"

interface IRoomServicesList {}

const RoomServicesList: React.FC<IRoomServicesList> = () => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const currentLang = localStorage.getItem("i18nextLng")

  const { categories } = useAppSelector(currentObject)

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale("en")
    }
  }, [currentLang])

  return (
    <div className={`${styles.RoomServicesList}`}>
      <div className={`${styles.RoomServicesList__title}`}>{t("services")}</div>
      <div className={styles.RoomServicesList__list}>
        {categories.length ? (
          <>
            <div className={`${styles.RoomServicesList__listTop}`}>
              {categories.slice(0, 3).map((el: any, ind: number) => {
                return (
                  <RoomServicesEl
                    key={ind}
                    to={`category/${el.id}`}
                    title={el.name}
                    background={servicesBackgroundList[ind % 10]}
                    descr={el.description}
                    img={el.image_id}
                  />
                )
              })}
            </div>
            {categories.length > 3 && (
              <div className={`${styles.RoomServicesList__listBottom}`}>
                {categories.slice(3, categories.length).map((el: any, ind: number) => {
                  return (
                    <RoomServicesEl
                      key={ind}
                      to={`category/${el.id}`}
                      title={el.name}
                      background={servicesBackgroundList[(ind + 3) % 10]}
                      descr={el.description}
                      img={el.image_id}
                    />
                  )
                })}
              </div>
            )}
          </>
        ) : (
          <>
            <div className={`${styles.RoomServicesList__listTop}`}>
              <div className={`selectBack ${stylesEl.RoomServicesEl}`} />
              <div className={`selectBack ${stylesEl.RoomServicesEl}`} />
              <div className={`selectBack ${stylesEl.RoomServicesEl}`} />
            </div>
            <div className={`${styles.RoomServicesList__listBottom}`}>
              <div className={`selectBack ${stylesEl.RoomServicesEl}`} />
              <div className={`selectBack ${stylesEl.RoomServicesEl}`} />
              <div className={`selectBack ${stylesEl.RoomServicesEl}`} />
              <div className={`selectBack ${stylesEl.RoomServicesEl}`} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default RoomServicesList
