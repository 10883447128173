// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoomServicesEl_RoomServicesEl__OfyjU {
  position: relative;
  min-height: 66px;
  padding: 12px;
  color: #fff;
  border-radius: 12px;
  background: green;
  cursor: pointer;
}
@media (min-width: 640px) {
  .RoomServicesEl_RoomServicesEl__OfyjU {
    min-height: 74px;
    padding: 24px 16px 16px 24px;
  }
}
.RoomServicesEl_RoomServicesEl__title__sdW9n {
  line-height: 100%;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}
@media (min-width: 640px) {
  .RoomServicesEl_RoomServicesEl__title__sdW9n {
    font-size: 24px;
  }
}
.RoomServicesEl_RoomServicesEl__descr__7GeBx {
  display: block;
  line-height: 110%;
  margin-top: 4px;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (min-width: 640px) {
  .RoomServicesEl_RoomServicesEl__descr__7GeBx {
    font-size: 18px;
    margin-top: 11px;
  }
}
.RoomServicesEl_RoomServicesEl__img__1Or69 {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  mix-blend-mode: overlay;
  opacity: 0.4;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/RoomServicesEl/RoomServicesEl.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;AACF;AAAE;EARF;IASI,gBAAA;IACA,4BAAA;EAGF;AACF;AADE;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;AAGJ;AAFI;EALF;IAMI,eAAA;EAKJ;AACF;AAHE;EACE,cAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,oBAAA;EACE,qBAAA;EACA,4BAAA;EACA,gBAAA;AAKN;AAJI;EATF;IAUI,eAAA;IACA,gBAAA;EAOJ;AACF;AAJE;EACE,UAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,uBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAMJ","sourcesContent":[".RoomServicesEl {\n  position: relative;\n  min-height: 66px;\n  padding: 12px;\n  color: #fff;\n  border-radius: 12px;\n  background: green;\n  cursor: pointer;\n  @media (min-width: 640px) {\n    min-height: 74px;\n    padding: 24px 16px 16px 24px\n  }\n\n  &__title {\n    line-height: 100%;\n    font-weight: 600;\n    font-size: 14px;\n    color: #fff;\n    @media (min-width: 640px) {\n      font-size: 24px;\n    }\n  }\n  &__descr {\n    display: block;\n    line-height: 110%;\n    margin-top: 4px;\n    font-size: 13px;\n    display: -webkit-box;\n      -webkit-line-clamp: 3;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n    @media (min-width: 640px) {\n      font-size: 18px;\n      margin-top: 11px;\n    }\n  }\n\n  &__img {\n    z-index: 1;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    mix-blend-mode: overlay;\n    opacity: 0.4;\n    object-fit: cover;\n    max-width: 100%;\n    max-height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RoomServicesEl": `RoomServicesEl_RoomServicesEl__OfyjU`,
	"RoomServicesEl__title": `RoomServicesEl_RoomServicesEl__title__sdW9n`,
	"RoomServicesEl__descr": `RoomServicesEl_RoomServicesEl__descr__7GeBx`,
	"RoomServicesEl__img": `RoomServicesEl_RoomServicesEl__img__1Or69`
};
export default ___CSS_LOADER_EXPORT___;
