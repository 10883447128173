// import Head from 'next/head'
import ProductsLayout from "../../components/ProductsLayout/ProductsLayout"

export default function Products() {
  return (
    <div className="wrapper">
      {/* <Head>
                <title>Staq Service</title>
                <meta name="description" content=""/>
                <link rel="icon" href="/favicon.ico"/>
            </Head> */}

      <main>
        <ProductsLayout />
      </main>
    </div>
  )
}
