import React, { useEffect, useRef, useState } from "react"
import styles from "./HistoryOrdModal.module.scss"
import Modal from "../../Modal/Modal"
import { useLazyGetServiceOrderQuery, useGetServiceOrderReviewsMutation } from "../../../redux/api/content"
import { useTranslation } from "react-i18next"
import moment from "moment"
import SwipeLine from "../../Modal/SwipeLine"
import HistoryOrdModalSkeleton from "./HistoryOrdModalSkeleton"
import { BACKEND_API_URL } from "../../../utils/constants"
import InformationRow from "./assets/InformationRow"
import FileRow from "./assets/FileRow"
import StarsRating from "../../Assets/StarsRating/StarsRating"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  openedOrder?: string
}

const formatedName = (name: string) => {
  const arr = name.split(" ")

  return arr.length > 1 ? `${arr[0]} ${arr[1][0]}.` : `${arr}`
}


const HistoryOrdModal: React.FC<Props> = ({ open, setOpen, openedOrder }) => {
  const [getServiceOrder, { data: orderInfo }] = useLazyGetServiceOrderQuery()
  const [getServiceOrderReviews] = useGetServiceOrderReviewsMutation()

  const [review, setReview] = useState<any>(null)
  const [order, setOrder] = useState<any>(null)

  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (openedOrder) {
      setLoading(true)
      getServiceOrder({ id: openedOrder })
        .then((result) => {
          setOrder(result.data.data)
          setLoading(false)
        })
        .catch((err) => console.log(err))
      getServiceOrderReviews({ service_order_id: openedOrder })
        .then((res: any) => setReview(res.data[0]))
        .catch((er) => console.log(er.message))
    }
  }, [openedOrder])

  const swipedModal: any = useRef(null)

  const ordData = orderInfo?.data

  return (
    <Modal open={open} setOpen={setOpen} addClass={"act-order"} refContent={swipedModal}>
      <div className={styles.historyOrdModal}>
        <SwipeLine swipedModal={swipedModal} setOpen={setOpen} />
        {loading ? (
          <HistoryOrdModalSkeleton />
        ) : (
          <>
            <div className={styles.historyOrdModal__top}>
              <div className={styles.historyOrdModal__topText}>
                <p>{t("service")}</p>
                <div>
                  {ordData?.name}
                  {ordData?.count > 1 ? <span>x{ordData.count}</span> : ""}
                </div>
              </div>
              <div className={styles.historyOrdModal__topImg}>
                <img
                  src={
                    ordData?.photos?.[0]
                      ? BACKEND_API_URL + "/public/photo/preview/" + ordData.photos[0]
                      : "/img/productNoImg.svg"
                  }
                  alt=""
                />
              </div>
            </div>
            {review && order?.hasReview && (
              <div className={styles.historyOrdModal__line}>
                <div className={`${styles.historyOrdModal__topImg} ${styles.historyOrdModal__topImg_small}`}>
                  <img
                    src={
                      order?.assignees[0]?.avatar_id
                        ? BACKEND_API_URL + "/web/user/avatar/" + order.assignees[0].avatar_id
                        : "/img/productNoImg.svg"
                    }
                    alt=""
                  />
                </div>
                <div className={styles.historyOrdModal__executorDescr}>
                  <p>{order?.assignees[0]?.name ? formatedName(order?.assignees[0]?.name) : t("executorNotFound")}</p>
                  <StarsRating disabled rating={review.rating} />
                </div>
              </div>
            )}
            <div className={styles.historyOrdModal__line}>
              <div className={styles.historyOrdModal__topText}>
                <p>{t("object")}</p>
                <h5>{ordData?.TechnicalObject}</h5>
              </div>
            </div>
            {!!ordData?.options?.length &&
              ordData.options.map((el: any, kk: number) => {
                if ((el.type === "text" || el.type === "number") && !!el.value) {
                  return (
                    <div className={styles.historyOrdModal__line} key={kk}>
                      <div className={styles.historyOrdModal__topText}>
                        <p>{el.name}</p>
                        <h5>{el.value}</h5>
                      </div>
                    </div>
                  )
                } else if (el.type === "boolean" || el.type === "checkbox") {
                  return (
                    <div className={styles.historyOrdModal__line} key={kk}>
                      <div className={styles.historyOrdModal__topText}>
                        <p>{el.name}</p>
                        <h5>{el?.value ? t("yes") : t("no")}</h5>
                      </div>
                    </div>
                  )
                } else if (el.type === "datetime") {
                  return (
                    <div className={styles.historyOrdModal__line} key={kk}>
                      <div className={styles.historyOrdModal__topText}>
                        <p>{el.name}</p>
                        <h5>
                          {el.value &&
                            (typeof el.value === "number"
                              ? moment(Number(el.value)).format("DD MMMM YYYY HH:mm")
                              : moment(el.value).format("DD MMMM YYYY"))}
                        </h5>
                      </div>
                    </div>
                  )
                } else if (el.type === "list" && el?.items?.length) {
                  return (
                    <div className={styles.historyOrdModal__line} key={kk}>
                      <div className={styles.historyOrdModal__topText}>
                        <p>{el.name}</p>
                        <h5>{el?.items?.map((ell: any, kkk: number) => ell?.name).join(", ")}</h5>
                      </div>
                    </div>
                  )
                } else if (el.type === "information") {
                  return <InformationRow el={el} key={kk} />
                } else if (el.type === "attachment" && el?.files?.length) {
                  return (
                    <div className={styles.historyOrdModal__line} key={kk}>
                      <div className={styles.historyOrdModal__topText}>
                        <div className={`${styles.orderModal__files}`}>
                          <p>{t("attachments")}</p>
                          {el?.files.map((ell: any, kkk: number) => <FileRow el={ell} key={kkk} />)}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            <div className={styles.historyOrdModal__line}>
              <div className={styles.historyOrdModal__topText}>
                <p>{t("date")}</p>
                <h5>{moment(ordData?.created_at).format("DD MMMM yyyy")}</h5>
              </div>
            </div>
            <div className={`${styles.historyOrdModal__line} ${styles.historyOrdModal__doubleline}`}>
              <div className={styles.historyOrdModal__topText}>
                <p>{t("orderTime")}</p>
                <h5>{moment(ordData?.created_at).format("HH:mm")}</h5>
              </div>
              {ordData?.finished_at && (
                <div className={styles.historyOrdModal__topText}>
                  <p>{t("orderTimeEnd")}</p>
                  <h5>{moment(ordData?.finished_at).format("HH:mm")}</h5>
                </div>
              )}
            </div>
            <div className={`${styles.historyOrdModal__line} ${styles.historyOrdModal__doubleline}`}>
              <div className={styles.historyOrdModal__topText}>
                <p>{t("orderNum")}</p>
                <h5>{ordData?.number}</h5>
              </div>
            </div>
            <div className={`${styles.historyOrdModal__bottom}`}>
              <button type="button" onClick={() => setOpen(false)} className={`btn btn_grey`}>
                {t("back")}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default HistoryOrdModal
