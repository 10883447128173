import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  ordersList: [],
}

export const ordersSlice = createSlice({
  name: "ordersList",
  initialState,
  reducers: {
    updateOrdersList: (state: any, action: PayloadAction<any>) => {
      state.ordersList = [...state.ordersList, ...action.payload.list]
    },
    changeOrderHasRevied: (state: any, action: PayloadAction<any>) => {
      const updatedArray = state.ordersList.map((el: any) => {
        if (el.id === action.payload.id) {
          return { ...el, hasReview: action.payload.hasReview }
        } else return {...el}
      })

      state.ordersList = updatedArray
    },
  },
})

export const { updateOrdersList, changeOrderHasRevied } = ordersSlice.actions
