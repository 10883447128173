// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrdersList_ordersList__CvIXg {
  width: 100%;
  padding-bottom: 50px;
}
.OrdersList_ordersList__title__trKao {
  margin-bottom: 12px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.OrdersList_ordersList__day__I1M9M {
  padding-top: 18px;
  padding-bottom: 16px;
}
.OrdersList_ordersList__headSk__690uF {
  border-radius: 4px;
  max-width: 208px;
  height: 32px;
  margin-top: 24px;
}
.OrdersList_ordersList__itemSk__LPI\\+a {
  margin-top: 8px;
  border-radius: 16px;
  width: 100%;
  height: 92px;
}

.OrdersList_nothingContent__UFqfl {
  margin-top: auto;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/OrdersList/OrdersList.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,oBAAA;AACF;AACE;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEE;EACE,iBAAA;EACA,oBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AADJ;AAIE;EACE,eAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAFJ;;AAMA;EACE,gBAAA;EACA,kBAAA;AAHF","sourcesContent":[".ordersList {\n  width: 100%;\n  padding-bottom: 50px;\n\n  &__title {\n    margin-bottom: 12px;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px;\n  }\n\n  &__day {\n    padding-top: 18px;\n    padding-bottom: 16px;\n  }\n\n  &__headSk {\n    border-radius: 4px;\n    max-width: 208px;\n    height: 32px;\n    margin-top: 24px;\n  }\n\n  &__itemSk {\n    margin-top: 8px;\n    border-radius: 16px;\n    width: 100%;\n    height: 92px;\n  }\n}\n\n.nothingContent {\n  margin-top: auto;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ordersList": `OrdersList_ordersList__CvIXg`,
	"ordersList__title": `OrdersList_ordersList__title__trKao`,
	"ordersList__day": `OrdersList_ordersList__day__I1M9M`,
	"ordersList__headSk": `OrdersList_ordersList__headSk__690uF`,
	"ordersList__itemSk": `OrdersList_ordersList__itemSk__LPI+a`,
	"nothingContent": `OrdersList_nothingContent__UFqfl`
};
export default ___CSS_LOADER_EXPORT___;
