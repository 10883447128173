import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: any = {
    technicalObject: {
        id: "",
        name: "",
        path_name: "",
        photos: [],
        shortName: "",
    },
    serviceCategories: [],
    categories: [],
    subCatsObject: [],
    servicesInCategory: [],
    allServicesInObject: []
}

export const objectSlice = createSlice({
    name: "object",
    initialState,
    reducers: {
        updateObjectPage: (state: any, action: PayloadAction<any>) => {            
            state.technicalObject = {...action.payload.technicalObject}
            state.serviceCategories = [...action.payload.serviceCategories]
            state.allServicesInObject = []
            action.payload.serviceCategories.map((el: any) => el.services)
            .forEach((el: any) => el.forEach((service: any) => state.allServicesInObject.push(service)))
        },
        updateRoomServicesList: (state: any, action: PayloadAction<any>) => {            
            state.categories = [...action.payload.data.data]
        },
        updateSubCatsObject: (state: any, action: PayloadAction<any>) => {            
            state.subCatsObject = [...action.payload.data.data.serviceCategories]
        },
        setServicesInCategory: (state: any, action: PayloadAction<any>) => {
            state.servicesInCategory = action.payload
        },
        resetServicesInCategory: (state: any) => {
            state.servicesInCategory = []
        },
        resetSubCatsLists: (state: any) => {            
            state.subCatsObject = []
        },
    },
})

export const { updateObjectPage, updateRoomServicesList, updateSubCatsObject, resetSubCatsLists, setServicesInCategory, resetServicesInCategory } = objectSlice.actions

export const currentObject = (state: any) => state[objectSlice.name]
