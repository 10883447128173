import React, { useEffect } from "react"
import styles from "./ServicesList.module.scss"
import ServiceEl from "./ServiceEl"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { languageModalOpen } from "../../redux/slice/languageModal"
import { useAppSelector } from "../../hooks"
import { logout, selectAuth } from "../../redux/slice/auth"
import moment from "moment"
import { langWithCurrentMomentCode } from "../../utils/helpers"
import { authModalOpen } from "../../redux/slice/authModal"
import { servicesBackgroundList } from "../../utils/helpers"

interface Props {
  setAuthModal?: any
  setAuthModalType?: any
}

const ServicesList: React.FC<Props> = ({ setAuthModal, setAuthModalType }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const currentLang = localStorage.getItem("i18nextLng")
  const user = useAppSelector(selectAuth)

  useEffect(() => {
    if (currentLang) {
      moment.locale(langWithCurrentMomentCode.get(currentLang))
    } else {
      moment.locale("en")
    }
  }, [currentLang])

  const setOpen = () => {
    dispatch(languageModalOpen({ flag: true }))
  }

  const { serviceList, serviceListLoading } = useAppSelector((state) => state.main)

  return (
    <div className={styles.serviceList}>
      <div
        className={`${styles.serviceList__title} ${
          serviceListLoading ? `selectBack ${styles.serviceList__title_load}` : ""
        }`}
      >
        {t("services")}
      </div>
      <div className={styles.serviceList__list}>
        {serviceListLoading && !serviceList.length ? (
          <>
            <div className={`${styles["serviceList__list-top"]}`}>
              <div className={`selectBack ${styles.serviceEl} ${styles.serviceEl__big}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
            </div>
            <div className={`${styles["serviceList__list-other"]}`}>
              <div className={`selectBack ${styles.serviceEl}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
              <div className={`selectBack ${styles.serviceEl}`} />
            </div>
          </>
        ) : (
          <>
            <div className={`${styles["serviceList__list-top"]}`}>
              {serviceList.slice(0, 3).map((el: any, ind: number) => {
                return (
                  <ServiceEl
                    size={!ind ? "wide" : ""}
                    title={el?.name}
                    subtitle={el?.description}
                    background={servicesBackgroundList[ind % 10]}
                    img={el?.image_id}
                    key={ind}
                    id={el.id}
                  />
                )
              })}
            </div>
            {serviceList.length > 3 && (
              <div className={`${styles["serviceList__list-other"]}`}>
                {serviceList.slice(3, serviceList.length).map((el: any, ind: number) => {
                  return (
                    <ServiceEl
                      size={""}
                      title={el?.name}
                      subtitle={el?.description}
                      background={servicesBackgroundList[(ind + 3) % 10]}
                      img={el?.image_id}
                      key={ind}
                      id={el.id}
                    />
                  )
                })}
              </div>
            )}
          </>
        )}

        {/* <ServiceEl dpClass={"two"} />
                <ServiceEl dpClass={"three"} />
                <ServiceEl dpClass={"four"} />
                <ServiceEl dpClass={"five"} />
                <ServiceEl dpClass={"six"} /> */}
      </div>
      <NoFindBtn size={"big"} isLoading={serviceListLoading} />
      <div className={styles.serviceList__logoutAndLang}>
        {serviceListLoading ? (
          <>
            <div className={`btn btn_grey ${styles.serviceList__changeLang} selectBack`} />
            <div className={`${styles.serviceList__logout} selectBack`} />
          </>
        ) : (
          <>
            <button
              type="button"
              className={`btn btn_grey ${styles.serviceList__changeLang}`}
              onClick={() => {
                setOpen()
              }}
            >
              {t("changeLang")}
              <img src={`/img/lang/${currentLang ?? "ru"}.png`} alt="" />
            </button>
            {user.token && (
              <button
                type="button"
                className={styles.serviceList__logout}
                onClick={() => {
                  dispatch(logout())
                  dispatch(authModalOpen({ flag: true }))
                }}
              >
                {t("logout")}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ServicesList
