import React, { useEffect, useState } from "react"
// import { useRouter } from 'next/router'
import styles from "./RoomLayout.module.scss"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { currentObject } from "../../redux/slice/object"
import { useAppSelector } from "../../hooks"
import { BACKEND_API_URL } from "../../utils/constants"
import { useGetCompanyLogoQuery } from "../../redux/api/auth"
import LocalizedLink from "../../hoc/LocalizedLink"
import Search from "../Search/Search"
// import { useGetProductsListMutation } from "../../redux/api/main"
// import Link from 'next/link'

interface Props {
  headRef?: any
  getSearchedList: (list: Array<any>) => void
  searchLoading: (bool: boolean) => void
  subCats?: number
  getIsSearching: (bool: boolean) => void
}

const RoomHead: React.FC<Props> = ({ headRef, getSearchedList, searchLoading, subCats, getIsSearching }) => {
  // const router = useRouter()
  const [searchBottom, setSearchBottom] = useState<number>(0)
  const [searchVal, setSearchVal] = useState<string | number>("")
  // const navigate = useNavigate()
  const [hideHead, setHideHead] = useState(false)
  const { technicalObject, servicesInCategory, subCatsObject, allServicesInObject } = useAppSelector(currentObject)
  const [searchedListInCategory, setSearchedListInCategory] = useState<Array<any>>([])
  const { data: logoCompany } = useGetCompanyLogoQuery()
  const queries = useParams()

  // const [getProductsList, { isLoading }] = useGetProductsListMutation()

  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const getPage = () => {
    if (queries.id && !queries.category_id) {
      return 1
    }
    if (queries.category_id) {
      return 2
    }
  }

  const searchBottomHundler = () => {
    if (subCatsObject?.length > 0) {
      getPage() === 2 && window.innerWidth <= 640
        ? setSearchBottom(-50)
        : getPage() === 2 && window.innerWidth > 640
        ? setSearchBottom(10)
        : setSearchBottom(0)
    } else setSearchBottom(0)
  }

  useEffect(() => {
    searchBottomHundler()
    window.addEventListener("resize", searchBottomHundler)

    return () => window.removeEventListener("resize", searchBottomHundler)
  }, [subCats])

  useEffect(() => {
    searchLoading(true)
    if (searchVal === "") {
      searchLoading(false)
      getSearchedList([])
      getIsSearching(false)
    }

    if (typeof searchVal === "string" && searchVal.trim().length > 0) {
      const timeoutId = setTimeout(() => {
        getIsSearching(true)
        searchLoading(true)
        if (getPage() === 2) {
          getSearchedList(servicesInCategory.filter((service: any) => service.name.toLowerCase().includes(searchVal)))
          searchLoading(false)
        } else {
          getSearchedList(allServicesInObject.filter((service: any) => service.name.toLowerCase().includes(searchVal)))
          searchLoading(false)
          // getProductsList({ keywords: searchVal })
          //   .unwrap()
          //   .then((res) => {
          //     searchLoading(false)
          //     getSearchedList(res.data.aItems)
          //     // setSearchedServiceList(res.data.aItems)
          //   })
          //   .catch((err) => console.log(err))
          // getSearchedList([])
        }
      }, 500)
      return () => clearTimeout(timeoutId)
    } else getSearchedList([])
  }, [searchVal, searchedListInCategory])

  return (
    <div className={`${styles.roomLayout__hero} ${hideHead ? styles.roomLayout__hero_hidden : ""}`}>
      <div className={styles["roomLayout__hero-back"]}>
        {!!technicalObject?.photos?.length && (
          <img src={BACKEND_API_URL + "/public/photo/preview/" + technicalObject?.photos?.[0]} alt="" />
        )}
        <div className={styles.roomLayout__searchBlock} style={{ bottom: searchBottom !== 0 ? searchBottom : "" }}>
          <Search searchVal={searchVal} setSearchVal={setSearchVal} className={styles.roomLayout__search} />
        </div>
      </div>
      <div className={styles["roomLayout__hero-content"]} ref={headRef}>
        <div className={styles["roomLayout__hero-head"]}>
          <LocalizedLink to={"../.."} relative="path" className={styles.titleBack__back} onClick={() => setSearchVal('')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15 6L9 12L15 18" stroke="#4B5058" strokeWidth="2" />
            </svg>
          </LocalizedLink>
          {logoCompany && logoCompany?.data?.length !== 0 ? (
            <div className={styles.header__logo}>
              <LocalizedLink to={"/"}>
                {/* <img className={styles.desc} src="/img/logo_sm.svg" alt="" /> */}
                <div className={styles.header__logo__container} dangerouslySetInnerHTML={{ __html: logoCompany }} />
              </LocalizedLink>
            </div>
          ) : null}
        </div>
        <div className={styles["roomLayout__hero-title"]}>{technicalObject.name}</div>
        <div className={styles["roomLayout__hero-bottom"]}>
          {/* <div className={styles.openBlock}>
            <div className={styles.openBlock__ico}></div>
            <div className={styles.openBlock__text}>
              <p>{t("open")}</p>
              <span>до 19:30</span>
            </div>
          </div>
          <a href={`tel:`} className={styles["roomLayout__hero-call"]}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M17.7071 13.7071L20.3552 16.3552C20.7113 16.7113 20.7113 17.2887 20.3552 17.6448C18.43 19.57 15.3821 19.7866 13.204 18.153L11.6286 16.9714C9.88504 15.6638 8.33622 14.115 7.02857 12.3714L5.84701 10.796C4.21341 8.61788 4.43001 5.56999 6.35523 3.64477C6.71133 3.28867 7.28867 3.28867 7.64477 3.64477L10.2929 6.29289C10.6834 6.68342 10.6834 7.31658 10.2929 7.70711L9.27175 8.72825C9.10946 8.89054 9.06923 9.13846 9.17187 9.34373C10.3585 11.7171 12.2829 13.6415 14.6563 14.8281C14.8615 14.9308 15.1095 14.8905 15.2717 14.7283L16.2929 13.7071C16.6834 13.3166 17.3166 13.3166 17.7071 13.7071Z"
                fill="#4B5058"
              />
            </svg>
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default RoomHead
