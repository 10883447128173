import React from 'react'
import styles from "../../OrderModal/OrderModal.module.scss"
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { BACKEND_API_URL } from '../../../../utils/constants'
import { getFileIco } from '../../../../utils/helpers'

interface Props {
    el:any
}

const FileRow:React.FC<Props> = ({el}) => {
    const {t} = useTranslation("translation", { keyPrefix: `interface` })
    const fileType = el?.filename?.substring(el?.filename?.lastIndexOf('.') + 1, el?.filename?.length)
    const [iconVisible, iconSrc] = getFileIco(fileType)
    
    return (
        <a href={`${BACKEND_API_URL}/public/attachment/${el?.id}`} className={`${styles.orderModal__file}`}>
        {iconVisible ? (
            <img src={`${iconSrc}` ?? ""} alt="" />) 
            : (<></>)
        }
            <div className={styles.orderModal__fileInfo}>
                <div className={styles.orderModal__fileName}>
                {el.filename}
                </div>
                <div className={styles.orderModal__fileSize}>
                {`${Number(el?.size / 1000000).toFixed(2)}Mb ${el?.createdAt ? moment(el?.createdAt * 1000).format(
                    `● DD MMM YYYY ${t('in')} HH:mm`
                ) : ""}`}
                </div>
            </div>
        </a>
    )
}

export default FileRow