import React from 'react'

interface Props {}

const BtnLoader = (props: Props) => {
  return (
    <div className="btn-loader"><img src={"/img/loader.png"} alt="Loading..." /></div>
  )
}

export default BtnLoader