// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectObjModal_selectObjModal__main__b24XF {
  margin-top: 16px;
}
.SelectObjModal_selectObjModal__list__\\+idTM {
  margin-top: 16px;
  min-height: 340px;
  max-height: 340px;
  overflow: auto;
  position: relative;
}
.SelectObjModal_selectObjModal__bottom__zbuk9 {
  margin-top: 16px;
}

.SelectObjModal_nothingContent__LXs37 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: calc(50% - 7px);
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/SelectObjModal/SelectObjModal.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,oBAAA;AAHF","sourcesContent":[".selectObjModal {\n  &__main {\n    margin-top: 16px;\n  }\n\n  &__list {\n    margin-top: 16px;\n    min-height: 340px;\n    max-height: 340px;\n    overflow: auto;\n    position: relative;\n  }        \n\n  &__bottom {\n    margin-top: 16px;\n  }\n}\n\n.nothingContent {\n  position: absolute;\n  text-align: center;\n  width: 100%;\n  top: calc(50% - 7px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectObjModal__main": `SelectObjModal_selectObjModal__main__b24XF`,
	"selectObjModal__list": `SelectObjModal_selectObjModal__list__+idTM`,
	"selectObjModal__bottom": `SelectObjModal_selectObjModal__bottom__zbuk9`,
	"nothingContent": `SelectObjModal_nothingContent__LXs37`
};
export default ___CSS_LOADER_EXPORT___;
