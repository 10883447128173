import React, { Fragment, useEffect, useRef, useState } from "react"
import Modal from "../../Modal/Modal"
import styles from "./OrderModal.module.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper/modules"
import RadioRow from "../../Assets/RadioRow/RadioRow"
import OrderModalBlock from "./OrderModalBlock"
import CheckBoxRow from "../../Assets/CheckBoxRow/CheckBoxRow"
import { useFormikContext } from "formik"
import DatePicker from "react-datepicker"
import moment from "moment"
import { useDropzone } from "react-dropzone"
import { SwiperOptions } from "swiper/types/swiper-options"
import { useTranslation } from "react-i18next"
import { BACKEND_API_URL } from "../../../utils/constants"
import { IOrder } from "../../../types/orderTypes"
import { NumericFormat } from "react-number-format"
import { useLocation, useParams } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import SwipeLine from "../../Modal/SwipeLine"
import { declensionNumber, getFileIco, timeUnitsForLang } from "../../../utils/helpers"
import OrderModalSkeleton from "./OrderModalSkeleton"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
import FileDropzone from "../../Assets/FileDropzone/FileDropzone"
import { useAppSelector } from "../../../hooks"
import OrderModalPhotoBlock from "./OrderModalPhotoBlock"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalInfo: IOrder
  setInitialValuesForm: React.Dispatch<React.SetStateAction<any>>
  setSelectObjModal: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  orderModalLoading?: boolean
}

const OrderModal: React.FC<Props> = ({
  open,
  setOpen,
  modalInfo,
  setInitialValuesForm,
  setSelectObjModal,
  isLoading,
  orderModalLoading,
}) => {
  const swipedModal: any = useRef(null)
  const [isHaveTechObj, setIsHaveTechObj] = useState(false)
  const [isError, setIsError] = useState(false)
  const { values, submitForm, setFieldValue, isSubmitting } = useFormikContext<{ [key: string]: any }>()

  const object = useAppSelector((state)=>state.object.technicalObject)  

  const currentLang = localStorage.getItem("i18nextLng")
  let currentTimeUnit =
    timeUnitsForLang[(currentLang as "ru" | "en" | "th") ?? "en"][
      modalInfo.execution_time_units as "minutes" | "hours" | "days"
    ]

  if (currentLang === "ru") {
    const firstTime = modalInfo.execution_time_from
    const secondTime = modalInfo.execution_time_to

    if (modalInfo.execution_time_units === "hours") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["час", "часа", "часов"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["часа", "часов", "часов"])
      }
    }
    if (modalInfo.execution_time_units === "days") {
      if (firstTime && secondTime) {
        currentTimeUnit = declensionNumber(Number(modalInfo.execution_time_to), ["день", "дня", "дней"])
      } else if ((firstTime && !secondTime) || (!firstTime && secondTime)) {
        currentTimeUnit = declensionNumber(Number(secondTime), ["дня", "дней", "дней"])
      }
    }
  }

  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  // Инпут файл
  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
    inputRef,
  }: any = useDropzone({
    noClick: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setFieldValue(`${inputRef.current.name}`, [
        ...values[inputRef.current.name],
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      ])
    },
  })

  // Превьюшки
  const thumbs = (id: string) =>
    values[id]?.map((file: any) => (
      <div className={styles.photoBlock__preview} key={file.name}>
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <button
          type="button"
          onClick={() =>
            setFieldValue(
              id,
              values[id].filter((el: any) => el.path !== file.path),
            )
          }
          className={styles.photoBlock__close}
        >
          <img src="/img/close.svg" alt="" />
        </button>
      </div>
    ))

  const swiperParams: SwiperOptions = {
    slidesPerView: "auto",
    modules: [Pagination],
    pagination: {
      type: "bullets",
      clickable: true,
    },
  }

  const { pathname } = useLocation()
  const params = useParams()
  // Страница конкретного объекта
  const isObjectPage = pathname.includes("object")

  useEffect(() => {
    if (modalInfo?.technicalObjects?.length > 0) setIsHaveTechObj(true)
    setInitialValuesForm(createInitialValues())
  }, [modalInfo])

  const createInitialValues = () => {
    const newInitVal: { [key: string]: any } = {
      service_id: modalInfo.id,
      count: 1,
    }
    if (isHaveTechObj && !isObjectPage) newInitVal["technical_object_id"] = undefined
    if (isHaveTechObj && isObjectPage) newInitVal["technical_object_id"] = params?.id

    modalInfo.options?.forEach((option, i) => {
      if (option.type === "text") newInitVal[option.id] = ""
      if (option.type === "boolean") newInitVal[option.id] = false
      if (option.type === "checkbox") newInitVal[option.id] = false
      if (option.type === "number") newInitVal[option.id] = null
      if (option.type === "datetime") newInitVal[option.id] = undefined
      if (option.type === "list") newInitVal[option.id] = []
      if (option.type === "photo") newInitVal[option.id] = []
      if (option.type === "attachment") newInitVal[option.id] = []
    }) 

    return newInitVal
  }

  const checkOnChecking = (id: string, field_id: string) => {
    const index = values[field_id].findIndex((el: string) => el === id)
    if (index > -1) {
      setFieldValue(field_id, [...values[field_id].toSpliced(index, 1)])
    } else {
      setFieldValue(field_id, [...values[field_id], id])
    }
  }

  const nextOrSubmitStep = () => {
    submitForm()
  }

  const addInfoForNumberInput = (el: any) => {
    let text = ""
    if (el.min || el.max) text += "."
    if (el.min) text += ` ${t("fromu")} ${el.min}`
    if (el.max) text += ` ${!el.min ? t("tou") : t("to")} ${el.max}`
    return text
  }  

  return (
    <Modal open={open} setOpen={setOpen} isOrder={true} refContent={swipedModal}>
      <form>
        <div className={styles.orderModal}>
          <SwipeLine swipedModal={swipedModal} setOpen={setOpen} />
          <div className={styles.orderModal__top} style={{display: (!orderModalLoading && !modalInfo?.photos?.length) ? "none" : "block"}}>
            <Swiper {...swiperParams}>
              {orderModalLoading ? (
                <SwiperSlide className={`selectBack`}></SwiperSlide>
              ) : 
                modalInfo?.photos?.map((el: string, kk: number) => (
                  <SwiperSlide key={kk}>
                    <img src={BACKEND_API_URL + "/public/photo/" + el} alt="Preview" />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <div className={`
            ${styles.orderModal__body} 
            ${orderModalLoading ? styles.orderModal__body_load : ""} 
            ${!orderModalLoading && !modalInfo?.options?.length ? styles.orderModal__roundedTop : ""}
          `}>
            {orderModalLoading ? (
              <OrderModalSkeleton />
            ) : (
              <>
                {(!!modalInfo?.technicalObjects?.length || isObjectPage) && 
                  <div className={`${styles.orderModal__object}`}>
                    <div className={`${styles.orderModal__objectIco}`}>
                      <img src="/img/object_ico.svg" alt="" />
                    </div>
                    <div className={`${styles.orderModal__objectText}`}>
                      <span>{t("selectedObject")}</span>
                      <p>{isObjectPage ?
                        object.name :
                        values?.technical_object_id ? 
                        modalInfo?.technicalObjects?.filter((el)=>el.id === values.technical_object_id)?.[0]?.name : 
                        modalInfo?.technicalObjects?.[0]?.name
                      }</p>
                    </div>
                  </div>
                }
                {!!modalInfo?.description && <div className={`modal-text`} dangerouslySetInnerHTML={{ __html: modalInfo?.description ?? "" }} />}
                {modalInfo?.options?.map((el: any, kk: number) => (
                  <Fragment key={kk}>
                    {el.type === "information" && 
                      <OrderModalBlock title={``}>
                        <div className={`${styles.orderModal__info}`}>
                          <div className={`${styles.orderModal__blockTitle}`}>
                            {el.name}
                          </div>
                          <div className={`${styles.orderModal__infoText}`} dangerouslySetInnerHTML={{ __html: el?.description?.trim() ?? "" }} />
                          {!!el?.photos?.length &&
                            <div className={styles.photoBlock}>
                              {el?.photos?.map((el:string, kk:number)=>
                                <div className={styles.photoBlock__preview} key={kk}>
                                  <img
                                    src={BACKEND_API_URL + "/public/photo/" + el}
                                    alt=""
                                  />
                                </div>
                              )}
                            </div>
                          }
                          {!!el?.attachments_info?.length &&
                            <div className={`${styles.orderModal__files}`}>
                              {el?.attachments_info?.map((el:any, kk:number) => {
                                const fileType = el?.filename?.substring(el?.filename?.lastIndexOf('.') + 1, el?.filename?.length)
                                const [iconVisible, iconSrc] = getFileIco(fileType)
                                return (
                                  <a href={`${BACKEND_API_URL}/public/attachment/${el?.id}`} className={`${styles.orderModal__file}`}  key={el?.id || kk}>
                                    {iconVisible ? (
                                      <img src={`${iconSrc}`} alt=""/>
                                    ) : (
                                      <></>
                                    )}
                                    <div className={styles.orderModal__fileInfo}>
                                      <div className={styles.orderModal__fileName}>
                                        {el.filename}
                                      </div>
                                      <div className={styles.orderModal__fileSize}>
                                        {`${Number(el?.size / 1000000).toFixed(2)}Mb ● ${moment(el?.createdAt * 1000).format(
                                          `DD MMM YYYY ${t('in')} HH:mm`
                                        )}`}
                                      </div>
                                    </div>
                                  </a>
                                )
                              })}
                            </div>
                          }
                        </div>
                      </OrderModalBlock>
                    }
                    {el.type === "photo" ? (
                      <OrderModalPhotoBlock
                        el={el}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ) : null}

                    {el.type === "text" ? (
                      <OrderModalBlock title={`${el?.name}`}>
                        <TextareaAutosize
                          minRows={1}
                          maxRows={100}
                          placeholder={t("enterComment")}
                          className={`textarea`}
                          onChange={(e) => setFieldValue(`${el.id}`, e.target.value)}
                        />
                      </OrderModalBlock>
                    ) : null}

                    {el.type === "list" ? (
                      <OrderModalBlock title={`${el?.name}`}>
                        {el.items.map((item: any, kkk: number) =>
                          el.multiple ? (
                            <CheckBoxRow
                              key={item.id}
                              name={item.id}
                              title={item.name}
                              onChange={() => checkOnChecking(item.id, el.id)}
                              value={item.id || ""}
                              checked={values[el.id]?.find((val: string[]) => val === item.id) || false}
                            />
                          ) : (
                            <RadioRow
                              key={item.id}
                              name={item.id}
                              title={item.name}
                              onChange={() => {
                                setFieldValue(`${el.id}`, [item.id])
                              }}
                              value={item.id || ""}
                              checked={values[el.id]?.find((val: string[]) => val === item.id) || false}
                            />
                          ),
                        )}
                      </OrderModalBlock>
                    ) : null}

                    {el.type === "datetime" ? (
                      <OrderModalBlock title={`${el?.name}`}>
                        <div className={styles.orderModal__picker}>
                          <input
                            type={el.only_date ? "date" : "datetime-local"}
                            placeholder={t("enterData")}
                            onChange={(e: any) => {
                              e.currentTarget.classList.add(styles.has_value)
                              if (e.target.value.trim().length) {
                                el.only_date
                                  ? setFieldValue(`${el.id}`, moment(e.target.value).format("YYYY-MM-DD"))
                                  : setFieldValue(`${el.id}`, moment(e.target.value).format("x"))
                              } else {
                                setFieldValue(`${el.id}`, undefined)
                                e.currentTarget.classList.remove(styles.has_value)
                              }
                            }}
                            value={
                              el.only_date
                                ? values[el.id]
                                  ? moment(values[el.id]).format("YYYY-MM-DD")
                                  : undefined
                                : values[el.id]
                                ? moment(Number(values[el.id])).format("YYYY-MM-DD HH:mm")
                                : undefined
                            }
                            min={moment().format("YYYY-MM-DD")}
                          />
                          <img src="/img/picker.svg" alt="" />
                        </div>
                      </OrderModalBlock>
                    ) : null}

                    {el.type === "number" ? (
                      <OrderModalBlock title={`${el?.name}${addInfoForNumberInput(el)}`}>
                        <NumericFormat
                          className={`input`
                          }
                          value={values[el.id] || ""}
                          allowNegative={!el.unsigned}
                          autoComplete="false"
                          onChange={(e) => setFieldValue(`${el.id}`, e.target.value)}
                          placeholder={t("enterNum")}
                          // isAllowed={(values: any) => {
                          //   const { floatValue } = values
                          //   if (floatValue === undefined) return true  
                          //   if (el.max) {
                          //     return floatValue <= Number(el.max)
                          //   }
                          //   return true
                          // }}
                          onBlur={(e) => { 
                            if (e.target.value && el.min && Number(e.target.value) < Number(el.min)) {
                              setIsError(true)
                              e.target.classList.add('error')
                              return
                            }
                            if (e.target.value && el.max && Number(e.target.value) > Number(el.max)) {
                              setIsError(true)
                              e.target.classList.add('error')
                              return
                            }
                            setIsError(false)
                            e.target.classList.remove('error')
                          }}
                          decimalScale={el.integer ? 0 : 2}
                        />
                      </OrderModalBlock>
                    ) : null}

                    {el.type === "checkbox" ? (
                      <OrderModalBlock title={``}>
                        <CheckBoxRow
                          name={el.id}
                          title={el.name}
                          onChange={() => setFieldValue(`${el.id}`, !values[el.id])}
                          value={values[el.id]}
                          checked={values[el.id] || false}
                        />
                      </OrderModalBlock>
                    ) : null}

                    {el.type === "boolean" ? (
                      <OrderModalBlock title={`${el.name}`}>
                        <RadioRow
                          name={el.name}
                          title={t("yes")}
                          onChange={() => {
                            setFieldValue(`${el.id}`, true)
                          }}
                          value={true}
                          checked={values[el.id] === true}
                        />
                        <RadioRow
                          name={el.name}
                          title={t("no")}
                          onChange={() => setFieldValue(`${el.id}`, false)}
                          value={false}
                          checked={values[el.id] === false}
                        />
                      </OrderModalBlock>
                    ) : null}
                    {el.type === "attachment" ? (
                      <OrderModalBlock title={`${el.name}`}>
                        <div className={`${styles.orderModal__files}`}>
                          <FileDropzone 
                            name={el.id}
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                        </div>
                      </OrderModalBlock>
                    ) : null}
                  </Fragment>
                ))}

                <div className={styles.orderModal__submitBlock}>
                  <div className={styles.orderModal__submitBlockHead}>
                    <h5>{modalInfo?.name}</h5>
                    <p>
                      {(() => {
                        if (!modalInfo.execution_time_from && !modalInfo.execution_time_to) {
                          return ""
                        } else if (modalInfo.execution_time_from && modalInfo.execution_time_to) {
                          return (
                            <>
                              {modalInfo.execution_time_from}-{modalInfo.execution_time_to} {currentTimeUnit}
                            </>
                          )
                        } else if (modalInfo.execution_time_from && !modalInfo.execution_time_to) {
                          return (
                            <>
                              {t("from")} {modalInfo.execution_time_from} {currentTimeUnit}
                            </>
                          )
                        } else if (!modalInfo.execution_time_from && modalInfo.execution_time_to) {
                          return (
                            <>
                              {t("to")} {modalInfo.execution_time_to} {currentTimeUnit}
                            </>
                          )
                        }
                      })()}
                    </p>
                  </div>
                  {!orderModalLoading && (
                    <div className={styles.orderModal__submitBlockBottom}>
                      {modalInfo?.is_multiorder ? (
                        <div className={styles.orderModal__calc}>
                          <button
                            type="button"
                            className={styles.orderModal__calcPlus}
                            onClick={() => setFieldValue("count", values.count - 1)}
                            disabled={values.count < 2}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M12 8L4 8"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div className={styles.orderModal__calcNum}>{values.count}</div>
                          <button
                            type="button"
                            className={styles.orderModal__calcPlus}
                            onClick={() => setFieldValue("count", values.count + 1)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M8 4L8 12"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 8L4 8"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : null}
                      {modalInfo?.service_action_name 
                      ? (
                      <button onClick={nextOrSubmitStep} type="button" className={`btn`} disabled={isLoading || isError}>
                        {modalInfo?.service_action_name}
                        {isSubmitting && <BtnLoader />}
                      </button>
                      ) : 
                      <></>
                      }
                      
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default OrderModal
