import React, { useEffect } from "react"
import styles from "./footer.module.scss"
import NoFindBtn from "../NoFindBtn/NoFindBtn"
import { useTranslation } from "react-i18next"
import { appHeight } from "../../utils/helpers"
import { useLocation, useParams } from "react-router-dom"
import { useAppDispatch } from "../../hooks"
import { languageModalOpen } from "../../redux/slice/languageModal"
import { getCookie } from "../../utils/cookies"
import { USER_TOKEN_COOKIE } from "../../utils/constants"
import { registerSW } from "../../utils/serviceWorkerRegistration"

interface Props {
  showBtn?: boolean
}

const Footer: React.FC<Props> = ({ showBtn }) => {
  const { i18n, t } = useTranslation("translation", { keyPrefix: `interface` })
  const params = useParams()
  // const currentLang = params?.locale

  // const token = getCookie(USER_TOKEN_COOKIE)

  // const dispatch = useAppDispatch()

  useEffect(() => {
    appHeight()

    // Запись в кэш изображений
    const imgs = document.getElementsByTagName("img")
    const imgsSrc:string[] = [] 
    // console.log(imgs);
    for (let i = 0; i < imgs.length; i++) {
      const src = imgs[i].getAttribute("src") ?? ""      
      if (!imgsSrc.includes(src)) imgsSrc.push(src)
    }
    registerSW();
    if (typeof caches !== "undefined") {
      caches?.open("my-cache-v1")?.then(function (cache) {      
        return cache.addAll(imgsSrc);
      })
    }
    // 

  //   // Выводить модалку выбора языка если нет выбранного языка в url и юзер не залогинен
  //   // setTimeout(()=>i18n.changeLanguage(currentLang ? currentLang : "en"), 10)
  //   i18n.changeLanguage(currentLang ? currentLang : "en")
  //   if ((!currentLang || currentLang === "dev") && !token) {      
  //     dispatch(languageModalOpen({ flag: true }))
  //   }
    
    window.addEventListener("resize", appHeight)
    return () => window.removeEventListener("resize", appHeight)
  }, [])

  return (
    <footer className={styles.footer}>
      {showBtn && <NoFindBtn />}
      <div className={styles.footer__wp}>
        <p>{t("digitalPlatf")}</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="106" height="48" viewBox="0 0 106 48" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.6596 36.564L57.5136 18.1853L53.0446 26.3096H61.9184V31.4368H50.2241L47.4035 36.564H40.9042L54.9861 11H60.0048L68.3818 26.2012V17.8965C68.3818 13.5275 71.1981 11 75.8197 11H89.9739C94.4873 11 97.3399 13.5275 97.3399 17.8965V29.8119C97.3399 30.3896 97.3036 30.9312 97.1953 31.4368H105.5V36.564H67.6596ZM76.7947 31.4368H88.9629C90.7683 31.3645 91.5987 30.3896 91.5987 28.4759V19.1241C91.5987 17.1382 90.66 16.1634 88.4573 16.1634H77.1921C74.9893 16.1634 74.0507 17.3188 74.0507 19.1241V28.4759C74.0866 30.2813 75.1339 31.3284 76.7947 31.4368Z"
            fill="url(#paint0_linear_7091_960111)"
          />
          <path
            d="M33.5022 36.564H39.2794V16.1272H52.0975V11H6.85475C2.88294 11 0.5 13.022 0.5 16.705V19.8824C0.5 23.2765 2.66644 24.8652 5.9161 25.2985L22.3809 27.3927C23.2116 27.501 23.681 28.0788 23.681 28.7648V29.7397C23.681 30.7146 23.103 31.3645 21.8393 31.3645H1.14986V36.5279H22.9588C26.6778 36.5279 29.3859 34.3614 29.3859 30.9674V27.7899C29.3859 24.5402 27.3999 22.771 24.3668 22.3377L7.54093 20.2435C6.56596 20.063 6.16892 19.3769 6.16892 18.7631V17.6799C6.16892 16.8133 6.74649 16.0912 7.97428 16.0912H33.5022V36.564Z"
            fill="url(#paint1_linear_7091_960111)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_7091_960111"
              x1="0.5"
              y1="37"
              x2="102.201"
              y2="18.6818"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#457CFA" />
              <stop offset="1" stopColor="#984CF4" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_7091_960111"
              x1="0.5"
              y1="37"
              x2="102.201"
              y2="18.6818"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#457CFA" />
              <stop offset="1" stopColor="#984CF4" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </footer>
  )
}

export default Footer