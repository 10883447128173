// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoFindBtn_noFindBtn__VjtU9 {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 8px;
  background: linear-gradient(90deg, #457CFA 0%, #984CF4 100%);
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  height: 44px;
}
.NoFindBtn_noFindBtn_big__i-g3K {
  height: 66px;
  margin-top: 12px;
}
@media (min-width: 640px) {
  .NoFindBtn_noFindBtn__VjtU9 {
    margin-top: 16px;
    height: 76px;
    font-size: 20px;
  }
  .NoFindBtn_noFindBtn__big__hdCNK {
    height: 104px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NoFindBtn/NoFindBtn.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,4DAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;AACJ;AAEE;EApBF;IAqBI,gBAAA;IACA,YAAA;IACA,eAAA;EACF;EACE;IACE,aAAA;EACJ;AACF","sourcesContent":[".noFindBtn {\n  display: flex;\n  padding: 12px 16px;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n  margin-top: 8px;\n  background: linear-gradient(90deg, #457CFA 0%, #984CF4 100%);\n  border-radius: 12px;\n  font-size: 15px;\n  font-weight: 600;\n  line-height: 16px;\n  width: 100%;\n  height: 44px;\n  // margin-top: auto;\n  &_big {\n    height: 66px;\n    margin-top: 12px;\n  }\n\n  @media (min-width: 640px) {\n    margin-top: 16px;\n    height: 76px;\n    font-size: 20px;\n\n    &__big {\n      height: 104px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noFindBtn": `NoFindBtn_noFindBtn__VjtU9`,
	"noFindBtn_big": `NoFindBtn_noFindBtn_big__i-g3K`,
	"noFindBtn__big": `NoFindBtn_noFindBtn__big__hdCNK`
};
export default ___CSS_LOADER_EXPORT___;
