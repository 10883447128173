// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoomServicesList_RoomServicesList__title__Cpq-8 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 7px;
  line-height: 163%;
  letter-spacing: 1.4px;
}
@media (min-width: 640px) {
  .RoomServicesList_RoomServicesList__title__Cpq-8 {
    font-weight: 600;
    margin-bottom: 3px;
    font-size: 32px;
    letter-spacing: normal;
  }
}
.RoomServicesList_RoomServicesList__list__5vlQK {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 640px) {
  .RoomServicesList_RoomServicesList__list__5vlQK {
    gap: 16px;
  }
}
.RoomServicesList_RoomServicesList__listTop__\\+Dxe3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;
}
@media (min-width: 640px) {
  .RoomServicesList_RoomServicesList__listTop__\\+Dxe3 {
    gap: 16px;
  }
}
.RoomServicesList_RoomServicesList__listTop__\\+Dxe3 a:first-child,
.RoomServicesList_RoomServicesList__listTop__\\+Dxe3 div:first-child {
  grid-row: 1/-1;
}
.RoomServicesList_RoomServicesList__listBottom__SIIY3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}
@media (min-width: 640px) {
  .RoomServicesList_RoomServicesList__listBottom__SIIY3 {
    gap: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/RoomServicesList/RoomServicesList.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;AAAJ;AACI;EAPF;IAQI,gBAAA;IACA,kBAAA;IACA,eAAA;IACA,sBAAA;EAEJ;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAEJ;AADI;EAJF;IAKI,SAAA;EAIJ;AACF;AAFE;EACE,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,QAAA;AAIJ;AAHI;EALF;IAMI,SAAA;EAMJ;AACF;AALI;;EAEE,cAAA;AAON;AAJE;EACE,aAAA;EACA,qCAAA;EACA,QAAA;AAMJ;AALI;EAJF;IAKI,SAAA;EAQJ;AACF","sourcesContent":[".RoomServicesList {\n  &__title {\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 700;\n    margin-bottom: 7px;\n    line-height: 163%;\n    letter-spacing: 1.4px;\n    @media (min-width: 640px) {\n      font-weight: 600;\n      margin-bottom: 3px;\n      font-size: 32px;\n      letter-spacing: normal;\n    }\n  }\n  &__list {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    @media (min-width: 640px) {\n      gap: 16px;\n    }\n  }\n  &__listTop {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: repeat(2, 1fr);\n    gap: 8px;\n    @media (min-width: 640px) {\n      gap: 16px;\n    }\n    a:first-child,\n    div:first-child {\n      grid-row: 1 / -1;\n    }\n  }\n  &__listBottom {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 8px;\n    @media (min-width: 640px) {\n      gap: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RoomServicesList__title": `RoomServicesList_RoomServicesList__title__Cpq-8`,
	"RoomServicesList__list": `RoomServicesList_RoomServicesList__list__5vlQK`,
	"RoomServicesList__listTop": `RoomServicesList_RoomServicesList__listTop__+Dxe3`,
	"RoomServicesList__listBottom": `RoomServicesList_RoomServicesList__listBottom__SIIY3`
};
export default ___CSS_LOADER_EXPORT___;
