import React from 'react'
import styles from "../Modals/OrderModal/OrderModal.module.scss"

interface Props {
    swipedModal: any
    setOpen: any
}

const SwipeLine:React.FC<Props> = ({swipedModal, setOpen}) => {

  // Убрать модалку свайпом
    let moveValStart = 0,
        moveVal = 0
    const moveStart = (e: any) => {
        document.body.classList.add("overscroll-behavior")
        moveValStart = e.changedTouches[0].clientY
        swipedModal.current.style.transition = "0s"
    }
    const moveDarg = (e: any) => {
        const touches = e.changedTouches[0]
        moveVal = touches.clientY - moveValStart
        swipedModal.current.style.transform = `translateY(${moveVal > 0 ? moveVal : 0}px)`
    }
    const moveEnd = () => {
        swipedModal.current.style.transition = "0.3s"
        if (moveVal > 60) {
        swipedModal.current.style.transform = `translateY(100vh)`
        setTimeout(() => setOpen(false), 300)
        } else {
        swipedModal.current.style.transform = `translateY(0px)`
        }
        document.body.classList.remove("overscroll-behavior")
        moveValStart = 0
        moveVal = 0
    }
    return (
        <div
            className={styles.orderModal__swipeHandler}
            onClick={() => null}
            onTouchStart={(e) => moveStart(e)}
            onTouchMove={(e) => moveDarg(e)}
            onTouchEnd={moveEnd}
            >
            <span></span>
        </div>
    )
}

export default SwipeLine