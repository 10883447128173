import React from 'react'
import styles from "../HistoryOrdModal.module.scss"
import { useTranslation } from 'react-i18next'
import { BACKEND_API_URL } from '../../../../utils/constants'
import moment from 'moment'
import FileRow from './FileRow'

interface Props {
  el: any
}

const InformationRow:React.FC<Props> = ({el}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  
  return (
    <div className={styles.historyOrdModal__line}>
      <div className={styles.historyOrdModal__topText}>
        <p>{t("information")}</p>
        <h5>{el.name}</h5>
        <div>
          <div className={`${styles.orderModal__infoText} ${styles.historyOrdModal__informText}`} dangerouslySetInnerHTML={{ __html: el?.description ?? "" }} />
          {!!el?.photos?.length &&
            <div className={styles.photoBlock}>
              {el?.photos?.map((el:string, kk:number)=>
                <div className={styles.photoBlock__preview} key={kk}>
                  <img
                    src={BACKEND_API_URL + "/public/photo/" + el}
                    alt=""
                  />
                </div>
              )}
            </div>
          }
          {!!el?.attachments_info?.length &&
            <div className={`${styles.orderModal__files}`}>
              <p>{t("attachments")}</p>
              {el?.attachments_info?.map((ell:any, kkk:number) => 
                <FileRow el={ell} key={kkk}/>
              )}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default InformationRow