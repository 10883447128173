import { api } from "./index"
// import { BaseResponseType } from '@/types/content'
// import { LoginRequest, UserData } from '@/types/auth'

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (data) => ({
        url: "login",
        method: "POST",
        params: { ...data },
      }),
    }),
    getSms: builder.mutation<any, { phoneNumber: string }>({
      query: (data) => ({
        url: "public/login/get-sms",
        method: "POST",
        body: { ...data },
      }),
    }),

    loginSms: builder.mutation<any, { phoneNumber: string; code: string }>({
      query: (data) => ({
        url: "public/login/sms",
        method: "POST",
        body: { ...data },
      }),
    }),
    checkToken: builder.mutation<any, string | null | boolean | void>({
      query: (token) => ({
        url: "public/login/check",
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    }),
    getCompanyLogo: builder.query<any, void>({
      query: () => ({
        url: `public/photo/company-logo`,
        responseHandler: "content-type",
      }),
    }),
    getSettings: builder.mutation<any, void>({
      query: () => ({
        url: "public/settings",
        method: "GET",
      }),
    }),
    setNewUserName: builder.mutation<any, { name: string }>({
      query: (data) => ({
        url: "public/user/set-name",
        method: "PUT",
        body: { ...data },
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useLoginMutation,
  useGetSmsMutation,
  useLoginSmsMutation,
  useCheckTokenMutation,
  useGetCompanyLogoQuery,
  useGetSettingsMutation,
  useSetNewUserNameMutation
} = authApi
