import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  serviceList: [],
  serviceListLoading: true,
  countOrdersWithoutReview: 0
}

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    updateServiceList: (state: any, action: PayloadAction<any>) => {
      state.serviceList = [...action.payload.list]
      state.serviceListLoading = false
    },
    updateCountOrdersWithoutReview: (state: any, action: PayloadAction<any>) => {
      state.countOrdersWithoutReview = action.payload.data.count
    }
  },
})

export const { updateServiceList, updateCountOrdersWithoutReview } = mainSlice.actions
export const countOrdersWithoutReview = (state: any) => state[mainSlice.name].countOrdersWithoutReview
