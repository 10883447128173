import { combineReducers } from "redux"
import { api } from "./api"
import { authSlice } from "./slice/auth"
import { languageModalSlice } from "./slice/languageModal"
import { authModalSlice } from "./slice/authModal"
import { ratingModalSlice } from "./slice/ratingModal"
import { mainSlice } from "./slice/main"
import { productsSlice } from "./slice/products"
import { objectSlice } from "./slice/object"
import { ordersSlice } from "./slice/ordersList"
// import { planing } from '@/redux/slices/planing'

const combineReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [authSlice.name]: authSlice.reducer,
  [mainSlice.name]: mainSlice.reducer,
  [productsSlice.name]: productsSlice.reducer,
  [languageModalSlice.name]: languageModalSlice.reducer,
  [authModalSlice.name]: authModalSlice.reducer,
  [ratingModalSlice.name]: ratingModalSlice.reducer,
  [objectSlice.name]: objectSlice.reducer,
  [ordersSlice.name]: ordersSlice.reducer,
})

export default combineReducer
