import React from "react"
import styles from "./OrderModal.module.scss"

interface Props {
  title?: string | number
  children: any
}

const OrderModalBlock: React.FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.orderModal__block}>
      {!!title && <div className={styles.orderModal__blockTitle}>{title}</div>}
      {children}
    </div>
  )
}

export default OrderModalBlock
