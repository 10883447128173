import React, { useEffect, useState } from "react"
import styles from "./RatingModal.module.scss"
import Modal from "../../Modal/Modal"
import { useTranslation } from "react-i18next"
import TextareaAutosize from "react-textarea-autosize"
import { useLocation } from "react-router-dom"
import {
  usePostOrderReviewMutation,
  usePostOrderModalWasOpenedMutation,
  useGetQuantityOrderWithoutReviewMutation,
  useLazyGetServiceOrderQuery
} from "../../../redux/api/content"
import HistoryOrdModalSkeleton from "../HistoryOrdModal/HistoryOrdModalSkeleton"
import { BACKEND_API_URL } from "../../../utils/constants"
import StarsRating from "../../Assets/StarsRating/StarsRating"
import { useSelector, useDispatch } from "react-redux"
import { ratingModalOpen } from "../../../redux/slice/ratingModal"
import { changeOrderHasRevied } from "../../../redux/slice/ordersList"
import { updateCountOrdersWithoutReview } from "../../../redux/slice/main"

interface IRatingModal {
  open?: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
  openedOrder: string
}

const RatingModal: React.FC<IRatingModal> = ({ openedOrder, open, setOpen }) => {
  const [step, setStep] = useState<number>(1)
  const [comment, setComment] = useState<string>("")
  const [rating, setRating] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [getServiceOrder] = useLazyGetServiceOrderQuery()
  const [orderInfo, setOrderInfo] = useState<any>(null)

  const [postOrderModalWasOpened] = usePostOrderModalWasOpenedMutation()
  const [getQuantityOrderWithoutReview] = useGetQuantityOrderWithoutReviewMutation()

  const [postOrderReview] = usePostOrderReviewMutation()

  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const { openModal } = useSelector((state: any) => state.ratingModal)

  const setFirstOpen = (flag: boolean) => {
    dispatch(ratingModalOpen({ flag: flag }))
  }

  useEffect(() => {
    open ? document.body.classList.add("noscroll") : document.body.classList.remove("noscroll")
    openModal ? document.body.classList.add("noscroll") : document.body.classList.remove("noscroll")
  }, [open, openModal])

  useEffect(() => {
    !location.pathname.includes("history") ? setStep(3) : setStep(1)
    setFirstOpen(true)
    setIsLoading(true)
    getServiceOrder({ id: openedOrder })
      .then((result) => {
        setOrderInfo(result?.data?.data)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false))
    // отправка события об открытии модалки
    postOrderModalWasOpened(openedOrder)
  }, [])

  const doClose = () => {
    document.body.classList.remove("noscroll")
    setOpen ? setOpen(false) : setFirstOpen(false)
  }

  const changeRatingReviewHandler = (value: any) => {
    setRating(value)
  }

  const submitForm = () => {
    const data = { id: openedOrder, textReview: comment, ratingReview: rating }
    setIsLoading(true)
    postOrderReview(data)
      .then(() => {
        setStep(2)
        dispatch(changeOrderHasRevied({ id: openedOrder, hasReview: true }))
        setComment("")
      })
      .then(() => {
        getQuantityOrderWithoutReview().then((res: any) => {
          dispatch(updateCountOrdersWithoutReview(res))
        })
      })
      .catch((er) => console.log(er.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Modal open={open || openModal} setOpen={setOpen || setFirstOpen}>
      {isLoading ? (
        <HistoryOrdModalSkeleton />
      ) : (
        <div className={styles.RatingModal}>
          {step === 1 ? (
            <>
              <div className={styles.RatingModal__title}>{t("evaluateTaskTitle")}</div>
              <div className={styles.RatingModal__block}>
                <div className={styles.RatingModal__executorBlockDescr}>
                  <div className={styles.RatingModal__executorTitle}>{t("executor")}</div>
                  <div className={styles.RatingModal__executorName}>{orderInfo && orderInfo?.assignees[0]?.name || t("executorNotFound")}</div>
                </div>
                <div className={`${styles.RatingModal__blockImg} ${styles.RatingModal__blockImg_small}`}>
                  <img
                    src={
                      orderInfo?.assignees[0]?.avatar_id
                        ? BACKEND_API_URL + "/web/user/avatar/" + orderInfo?.assignees[0]?.avatar_id
                        : "/img/productNoImg.svg"
                    }
                    alt="avatar"
                  />
                </div>
              </div>
              <div className={styles.RatingModal__ratingBlock}>
                <div className={styles.RatingModal__ratingTitle}>{t("rating")}</div>
                <StarsRating name="rating" changeRatingReview={changeRatingReviewHandler} />
              </div>
              <div className={`modal-input ${styles.RatingModal__input}`}>
                <div className={`input-label ${styles.RatingModal__ratingTitle}`}>{t("review")}</div>
                <TextareaAutosize
                  minRows={1}
                  maxRows={5}
                  placeholder={t("yourReview")}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>
              <div className={`modal-bottom ${styles.RatingModal__bottom}`}>
                <button type="button" onClick={doClose} className={`btn btn_grey btn_sm`}>
                  {t("back")}
                </button>
                <button type="button" onClick={submitForm} className={`btn`} disabled={!rating}>
                  {t("send")}
                </button>
              </div>
            </>
          ) : step === 2 ? (
            <>
              <div className={styles.RatingModal__title}>{t("thanksReviewTitle")}</div>
              <div className={styles.RatingModal__descrBlock}>
                {/* <div className={styles.RatingModal__descr}>
              {t("thanksReviewDescr")} <p>5%</p>
            </div>
            <div className={styles.RatingModal__descr}>{t("thanksReviewMoreDescr")}</div> */}
                <div className={styles.RatingModal__descr}>{t("thanksReviewCommonDescr")}</div>
              </div>
              <button type="button" onClick={doClose} className={`btn`}>
                {t("itIsClear")}
              </button>
              <div className={styles.RatingModal__footer}>
                <p>{t("digitalPlatf")}</p>
                <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.772 11.6508L16.8006 6.26837L15.4918 8.64764H18.0906V10.1492H14.6658L13.8398 11.6508H11.9364L16.0604 4.16406H17.5302L19.9835 8.61592V6.18379C19.9835 4.90427 20.8083 4.16406 22.1618 4.16406H26.307C27.6288 4.16406 28.4642 4.90427 28.4642 6.18379V9.67336C28.4642 9.84254 28.4536 10.0012 28.4219 10.1492H30.854V11.6508H19.772ZM22.4473 10.1492H26.0109C26.5396 10.128 26.7828 9.84254 26.7828 9.28209V6.54331C26.7828 5.96172 26.5079 5.67621 25.8628 5.67621H22.5637C21.9186 5.67621 21.6437 6.01459 21.6437 6.54331V9.28209C21.6542 9.81081 21.9609 10.1175 22.4473 10.1492Z"
                    fill="#8A8D93"
                  />
                  <path
                    d="M9.7686 11.6508H11.4605V5.66563H15.2144V4.16406H1.96458C0.801388 4.16406 0.103516 4.75624 0.103516 5.83483V6.76539C0.103516 7.75938 0.737983 8.22464 1.68968 8.35155L6.51159 8.96485C6.75489 8.99658 6.89233 9.16578 6.89233 9.36669V9.65219C6.89233 9.93772 6.72308 10.128 6.35297 10.128H0.293835V11.6402H6.68084C7.76999 11.6402 8.56308 11.0057 8.56308 10.0117V9.08118C8.56308 8.12948 7.98148 7.61134 7.09319 7.48443L2.16553 6.87113C1.88 6.81826 1.76372 6.61733 1.76372 6.43757V6.12034C1.76372 5.86656 1.93287 5.65507 2.29245 5.65507H9.7686V11.6508Z"
                    fill="#8A8D93"
                  />
                </svg>
              </div>
            </>
          ) : step === 3 ? (
            <>
              <div className={styles.RatingModal__block}>
                <div className={styles.RatingModal__executorBlockDescr}>
                  <div className={styles.RatingModal__ratingTitle}>{t("serviceCompleted")}</div>
                  <div className={styles.RatingModal__service}>
                    {orderInfo?.name}
                    {orderInfo?.count > 1 && <span> x{orderInfo?.count}</span>}
                  </div>
                </div>
                <div className={styles.RatingModal__blockImg}>
                  <img
                    src={
                      orderInfo?.photos?.[0]
                        ? BACKEND_API_URL + "/public/photo/preview/" + orderInfo?.photos?.[0]
                        : "/img/productNoImg.svg"
                    }
                    alt="orderPhoto"
                  />
                </div>
              </div>
              <div className={styles.RatingModal__block}>
                <div className={styles.RatingModal__executorBlockDescr}>
                  <div className={styles.RatingModal__executorTitle}>{t("executor")}</div>
                  <div className={styles.RatingModal__executorName}>{orderInfo?.assignees[0]?.name || t("executorNotFound")}</div>
                </div>
                <div className={`${styles.RatingModal__blockImg} ${styles.RatingModal__blockImg_small}`}>
                  <img
                    src={
                      orderInfo?.assignees[0]?.avatar_id
                        ? BACKEND_API_URL + "/web/user/avatar/" + orderInfo?.assignees[0]?.avatar_id
                        : "/img/productNoImg.svg"
                    }
                    alt="avatar"
                  />
                </div>
              </div>
              <div className={styles.RatingModal__ratingBlock}>
                <div className={styles.RatingModal__ratingTitle}>{t("rating")}</div>
                <StarsRating name="rating" changeRatingReview={changeRatingReviewHandler} />
              </div>
              <div className={`modal-input ${styles.RatingModal__input}`}>
                <div className={`input-label ${styles.RatingModal__ratingTitle}`}>{t("review")}</div>
                <TextareaAutosize
                  minRows={1}
                  maxRows={5}
                  placeholder={t("yourReview")}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>
              <div className={`modal-bottom ${styles.RatingModal__bottom}`}>
                <button type="button" onClick={doClose} className={`btn btn_grey`}>
                  {t("skip")}
                </button>
                <button type="button" onClick={submitForm} className={`btn`} disabled={!rating}>
                  {t("evaluate")}
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </Modal>
  )
}

export default RatingModal
