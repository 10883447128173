import React, { useEffect, useState } from "react"
import styles from "./OrdersList.module.scss"
import OrderItem from "../OrderItem/OrderItem"
import moment from "moment"
import { useTranslation } from "react-i18next"
import OrdersSkeleton from "./OrdersSkeleton"

interface IActiveOrder {
  created_at: string
  id: string
  name: string
  path_name: string
  photos: string[]
  count: number
  hasReview: boolean
}
interface Props {
  type?: string
  clickFcn?: any
  orders: IActiveOrder[]
  isLoading?: boolean
  isFirstRender?: boolean
  isLoadingAppend?: boolean
  layout?: string
  ratingFn?: (order: any) => void
}

const OrdersList: React.FC<Props> = ({
  type,
  clickFcn,
  orders,
  isLoading,
  isFirstRender,
  isLoadingAppend,
  layout,
  ratingFn,
}) => {
  const [editedArr, setEditedArr] = useState<{ [key: string]: any }[]>([])
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const createOrdersArrWithDate = (orders: IActiveOrder[]) => {
    const newArr: any[] = []

    const createNewObj = (obj: IActiveOrder) => {
      const newObj: { [key: string]: any } = {}
      newObj.date = `${moment(obj.created_at)}`
      newObj.orders = [obj]
      newArr.push(newObj)
    }

    orders.forEach((el) => {
      if (newArr.length === 0) {
        createNewObj(el)
      } else {
        newArr.forEach((nr, i) => {
          if (moment(nr.date).isSame(el.created_at, "day")) {
            newArr[i].orders.push(el)
          } else if (i === newArr.length - 1) {
            createNewObj(el)
          }
        })
      }
    })
    return newArr
  }

  useEffect(() => {
    setEditedArr(createOrdersArrWithDate(orders))
  }, [orders])

  return (
    <>
      {isLoading || isFirstRender ? (
        <OrdersSkeleton isMainLoad />
      ) : (
        <>
          {editedArr.length ? (
            <div className={styles.ordersList}>
              {editedArr.map((el: any, i: number) => (
                <div className={styles.ordersList__day} key={i}>
                  <div className={styles.ordersList__title}>{moment(el.date).format("DD MMMM YYYY")}</div>
                  <div className={styles.ordersList__list}>
                    {el.orders.map((ell: IActiveOrder, ii: number) => (
                      <OrderItem
                        ratingFn={() => (ratingFn ? ratingFn(ell.id) : null)}
                        layout={layout}
                        key={ii}
                        img={ell.photos[0]}
                        title={ell.name}
                        subtitle={ell.path_name}
                        time={moment(ell.created_at).format("kk:mm")}
                        clickFcn={() => clickFcn(ell.id)}
                        count={ell.count}
                        orderId={ell.id}
                        hasReview={ell?.hasReview}
                      />
                    ))}
                  </div>
                </div>
              ))}
              {isLoadingAppend ? <OrdersSkeleton /> : <></>}
            </div>
          ) : (
            <p className={styles.nothingContent}>{t("actOrHistOrdersNotFound")}</p>
          )}
        </>
      )}
    </>
  )
}

export default OrdersList
