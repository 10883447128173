import React from "react"
import Modal from "../../Modal/Modal"
import styles from "../AuthModal/AuthModal.module.scss"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { languageModalOpen } from "../../../redux/slice/languageModal"
import { useNavigate, useParams } from "react-router-dom"

interface Props {
  isObject?:boolean //Модалка на странице объекта
}

const LanguageModal: React.FC<Props> = ({isObject}) => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: `interface` })

  const dispatch = useDispatch()
  const { openModal } = useSelector((state: any) => state.languageModal)

  const params = useParams()
  const navigate = useNavigate();

  const langList: { text: string; value: string }[] = [
    {
      text: "English",
      value: "en",
    },
    {
      text: "ภาษาไทย",
      value: "th",
    },
    // {
    //   text: "China",
    //   value: "cn",
    // },
    // {
    //   text: "Français",
    //   value: "fr",
    // },
    {
      text: "Русский",
      value: "ru",
    },
  ]

  const setOpen = (flag: boolean) => {
    dispatch(languageModalOpen({ flag: flag }))
  }
  
  // Функция выбора языка
  const setLanguage = (lang: string) => {
    setOpen(false)

    i18n.changeLanguage(lang)
    // let setURL = lang !== "en" ? "/"+lang : "/"
    // Если объект, то стролить url с учётом пути
    // if (isObject) {
    //   setURL = `${lang !== "en" ? "/"+lang : "/"}/object/${params?.id}`
    // }
    // navigate(setURL)
  }

  return (
    <Modal open={openModal} setOpen={setOpen}>
      <div className={styles.authModal}>
        <div>
          <div className={`modal-head`}>
            <div className={`modal-title`}>{t("selectLang")}</div>
            <img src="/img/logo_staq_sm.svg" alt="" />
          </div>
          <div className={`modal-text`}>{t("selectLangText")}</div>
          <div className={`modal-input`}>
            {langList.map((el, kk: number) => (
              <button key={kk} onClick={() => setLanguage(el.value)} className={styles.authModal__langBtn}>
                <img src={`/img/lang/${el.value}.png`} alt="language" />
                <span>{el.text}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M9 6L15 12L9 18" stroke="#4B5058" strokeWidth="2" />
                </svg>
              </button>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LanguageModal
