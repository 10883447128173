import React, { useEffect, useState } from "react"
import Modal from "../../Modal/Modal"
import { useTranslation } from "react-i18next"
import { useFormikContext } from "formik"
import TextareaAutosize from "react-textarea-autosize"
import BtnLoader from "../../Assets/BtnLoader/BtnLoader"
// import styles from "./NoFindModal.module.scss"

interface Props {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setNextModal: React.Dispatch<React.SetStateAction<boolean>>
}

const NoFindModal: React.FC<Props> = ({ open, setOpen, setNextModal }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const { values, setFieldValue, submitForm, isSubmitting } = useFormikContext<{ [key: string]: any }>()

  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const openNextModal = () => {
    if (values.text && values.text.trim().length > 0) {
      submitForm()
    }
  }

  useEffect(() => {
    if (!open) setFieldValue("text", "")
  }, [open])

  useEffect(() => {
    if (values.text.length > 0) {
      setIsDisabled(false)
    } else setIsDisabled(true)
  }, [values.text])

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className={`application-for-new-service`}>
        <div className={`modal-head`}>
          <div className={`modal-title`}>{t("sendReq")}</div>
        </div>
        <div className={`modal-text`}>{t("discribeReq")}</div>
        <div className={`modal-input modal-input_cancel`}>
          <div className={`input-label`}>{t("comment")}</div>
          <TextareaAutosize
            minRows={1}
            maxRows={3}
            placeholder={t("enterComment")}
            onChange={(e) => setFieldValue("text", e.target.value)}
            value={values.text}
          />
        </div>
        <div className={`modal-bottom`}>
          <button type="button" onClick={() => setOpen(false)} className={`btn btn_grey btn_sm`}>
            {t("back")}
          </button>
          <button type="button" onClick={openNextModal} className={`btn`} disabled={isDisabled}>
            {t("send")}
            {isSubmitting && <BtnLoader />}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default NoFindModal
