import React from 'react'
import styles from "./OrderModal.module.scss"

interface Props {}

const OrderModalSkeleton = (props: Props) => {
  return (
    <>
        <div className={`selectBack ${styles.orderModal__sk1}`} />
        <div className={`selectBack ${styles.orderModal__sk2}`} />
        <div className={`selectBack ${styles.orderModal__sk3}`} />
        <div className={`selectBack ${styles.orderModal__sk4}`} />
        <div className={`${styles.orderModal__sk5}`}>
            <span className={`selectBack`} />
            <div className={`selectBack`} />
        </div>
        <div className={`${styles.orderModal__sk5}`}>
            <span className={`selectBack`} />
            <div className={`selectBack`} />
        </div>
        <div className={`${styles.orderModal__sk5}`}>
            <span className={`selectBack`} />
            <div className={`selectBack`} />
        </div>
    </>
    
  )
}

export default OrderModalSkeleton