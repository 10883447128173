import React, { useEffect } from "react"
import styles from "./OrdersLine.module.scss"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { selectActiveOrders } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import LocalizedLink from "../../hoc/LocalizedLink"
import { useGetQuantityOrderWithoutReviewMutation } from "../../redux/api/content"
import { useAppDispatch } from "../../hooks"
import { updateCountOrdersWithoutReview, countOrdersWithoutReview } from "../../redux/slice/main"

interface Props {
  isLoading?: boolean
}

const OrdersLine: React.FC<Props> = ({ isLoading }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const activeOrders = useAppSelector(selectActiveOrders)

  const dispatch = useAppDispatch()
  const quantityOrdersWithoutReview = useAppSelector(countOrdersWithoutReview)

  const [getQuantityOrderWithoutReview] = useGetQuantityOrderWithoutReviewMutation()

  useEffect(() => {
    if (!isLoading) {
      getQuantityOrderWithoutReview().then((res: any) => {
        dispatch(updateCountOrdersWithoutReview(res))
      })
    }
  }, [isLoading])

  return (
    <div className={styles.ordersLine}>
      {isLoading ? (
        <>
          <div className={`${styles.ordersLine__active} selectBack`} />
          <div className={`${styles.ordersLine__history} selectBack`} />
        </>
      ) : (
        <>
          <LocalizedLink to={"/orders"} className={styles.ordersLine__active}>
            <p>{t("actRequests")}</p>
            <span>{activeOrders}</span>
          </LocalizedLink>
          <LocalizedLink to={"/history"} className={styles.ordersLine__history}>
            <p>{t("historyRequests")}</p>
            {quantityOrdersWithoutReview > 0 && (
              <span className={`${styles.ordersLine__ordersNoReview}`}>{quantityOrdersWithoutReview}</span>
            )}
          </LocalizedLink>
        </>
      )}
    </div>
  )
}

export default OrdersLine
