import _ from "lodash"

export const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty("--app-height", `${window.innerHeight}px`)
}

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const timeUnitsForLang = {
  ru: {
    minutes: "мин",
    hours: "час",
    days: "день",
  },
  en: {
    minutes: "minutes",
    hours: "hours",
    days: "days",
  },
  th: {
    minutes: "นาที",
    hours: "ชั่วโมง",
    days: "วัน",
  },
  // cn: {
  //   minutes: "分钟",
  //   hours: "小时",
  //   days: "天",
  // },
  // fr: {
  //   minutes: "minutes",
  //   hours: "heures",
  //   days: "jour",
  // },
}

export const declensionNumber = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`
}

export const langWithCurrentMomentCode = new Map([
  ["en", "en"],
  ["th", "th"],
  // ["fr", "fr"],
  // ["cn", "zh-cn"],
  ["ru", "ru"],
])

export const getKeyLangMap = (val: string) => {
  //@ts-ignore
  return [...langWithCurrentMomentCode].find(([key, value]) => val.toLowerCase() === value.toLowerCase())
}

export function prefixPath (path:any, prefix:any) {
  return `/${prefix}/${_.trim(path, '/')}`
}

export const getFileIco = (fileType:string) => {
  let icoSrc = ""
  const formats = ["pdf", "mov", "avi", "mp4", "3gp", "doc", "docx", "img", "jpg", "png", "jpeg", "webp", "gif", "xls", "xlsx", "xmlx", "xml", "wmv", "txt"]
  switch (fileType) {
    case "pdf":
      icoSrc = "/img/icons/pdf.svg"
      break;
    case "mov":
    case "avi":
    case "mp4":
    case "3gp":
    case "wmv":
      icoSrc = "/img/icons/mov.svg"
      break;
    case "doc":
    case "docx":
    case "txt":
      icoSrc = "/img/icons/doc.svg"
      break;
    case "img":
    case "jpg":
    case "png":
    case "jpeg":
    case "webp":
    case "gif":
      icoSrc = "/img/icons/img.svg"
      break;
    case "xls":
    case "xlsx":
    case "xml":
    case "xmlx":
      icoSrc = "/img/icons/xls.svg"
      break;
  }

  return [formats.includes(fileType.toLocaleLowerCase()), String(icoSrc ?? "")]
}

export const servicesBackgroundList = [
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), #D55D8B",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), #6C78EE",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), #58C3E5",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), #F55E5F",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), #58C083",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), #F2B818",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), rgb(132 24 242)",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), rgb(242 24 24)",
  "linear-gradient(315deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.00) 100%), rgb(242 24 181 / 66%)",
]