import { configureStore } from "@reduxjs/toolkit"
import combineReducer from "./rootReducer"
import { api } from "./api"

const isDev = process.env.NODE_ENV === "development"

export const store = configureStore({
  reducer: combineReducer,
  devTools: isDev,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit'
// // import combineReducer from './rootReducer'
// import rootReducer from './rootReducer'
// import { api } from './api'

// let store: ReturnType<any>

// // export const store = configureStore({
// //   reducer: combineReducer,
// // })

// const isDev = process.env.NODE_ENV === 'development'

// const makeStore = () => {
//   store = configureStore({
//     reducer: rootReducer,
//     devTools: isDev,
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
//   })

//   return store
// }

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// export type AppStore = ReturnType<typeof makeStore>
// export type AppDispatch = AppStore['dispatch']
// export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// // export type AppDispatch = typeof store.dispatch
// export { store }
