import { api } from "./index"

export const contentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    //создать заявку
    postCreateOrder: builder.mutation<any, FormData>({
      query: (data) => ({
        url: `public/service-orders/`,
        method: "POST",
        body: data,
      }),
    }),
    //получить список заявок
    postServiceOrders: builder.query<any, { limit?: number; offset?: number; isActive: boolean }>({
      query: (data) => ({
        url: `public/service-orders/getlist`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Orders"],
    }),
    //получить информацию о заявке
    getServiceOrder: builder.query<any, { id: string }>({
      query: (data) => ({
        url: `public/service-orders/${data.id}`,
      }),
    }),
    //отменить заявку
    postCancelOrder: builder.mutation<any, { id: string; reason: string }>({
      query: (data) => ({
        url: `public/service-orders/cancel/${data.id}`,
        method: "POST",
        body: { reason: data.reason },
      }),
      invalidatesTags: ["Orders"],
    }),
    // отзывы
    getServiceOrderReviews: builder.mutation<any, { service_order_id: string }>({
      query: (data) => ({
        url: `public/service-orders/${data.service_order_id}/reviews`,
        method: "GET",
      }),
    }),
    getQuantityOrderWithoutReview: builder.mutation<any, void>({
      query: () => ({
        url: `public/service-orders/count-orders-without-reviews`,
        method: "GET",
      }),
    }),
    postOrderReview: builder.mutation<any, any>({
      query: (data) => ({
        url: `public/service-orders/${data.id}/reviews`,
        method: "POST",
        body: {
          text : data.textReview,
          rating: data.ratingReview
        },
      }),
    }),
    postOrderModalWasOpened: builder.mutation<any, any>({
      query: (service_order_id) => ({
        url: `public/service-orders/${service_order_id}/modal-review-impression`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostCreateOrderMutation,
  usePostServiceOrdersQuery,
  useLazyPostServiceOrdersQuery,
  useGetServiceOrderQuery,
  useLazyGetServiceOrderQuery,
  usePostCancelOrderMutation,
  useGetServiceOrderReviewsMutation,
  usePostOrderReviewMutation,
  useGetQuantityOrderWithoutReviewMutation,
  usePostOrderModalWasOpenedMutation
} = contentApi
