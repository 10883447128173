import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "../src/styles/globals.scss"
import enUs from "date-fns/locale/en-US"
import ru from "date-fns/locale/ru"
import th from "date-fns/locale/th"
// import fr from "date-fns/locale/fr"
// import zhCN from "date-fns/locale/zh-CN"
import { registerLocale } from "react-datepicker"
registerLocale("en", enUs)
registerLocale("ru", ru)
registerLocale("th", th)
// registerLocale("fr", fr)
// registerLocale("cn", zhCN)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <>
    <App />
  </>,
)
