// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StarsRating_stars__UUMSY {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 400px) {
  .StarsRating_stars__UUMSY {
    justify-content: flex-start;
    width: -moz-fit-content;
    width: fit-content;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/StarsRating/StarsRating.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,2BAAA;EACA,8BAAA;EACA,WAAA;AACF;AACE;EAPF;IAQI,2BAAA;IACA,uBAAA;IAAA,kBAAA;EAEF;AACF","sourcesContent":[".stars {\n  width: fit-content;\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  width: 100%;\n  // column-gap: 11px;\n  @media (min-width: 400px) {\n    justify-content: flex-start;\n    width: fit-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stars": `StarsRating_stars__UUMSY`
};
export default ___CSS_LOADER_EXPORT___;
