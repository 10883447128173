import React from "react"
import styles from "./RadioRow.module.scss"

interface Props {
  name: string
  title?: string
  value: any
  onChange: any
  checked: any
}

const RadioRow: React.FC<Props> = ({ name, title, value, onChange, checked }) => {
  return (
    <label className={`${styles.radioRow}`}>
      <input type="radio" name={name} onChange={onChange} value={value} checked={checked} hidden />
      <div className={styles.radioRow__itemIco} />
      <div className={styles.radioRow__itemText}>{title}</div>
    </label>
  )
}

export default RadioRow
