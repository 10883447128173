// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_search__JtbP4 {
  margin-top: 8px;
  line-height: 0;
  position: relative;
  border-radius: 8px;
}
.Search_search_loading__ubTs\\+ {
  width: 100%;
  height: 30px;
}
.Search_search__JtbP4 input {
  width: 100%;
  padding-right: 26px;
  padding-left: 16px;
  height: 36px;
  border-radius: 8px;
  background: #EEE;
  font-size: 14px;
  line-height: 36px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.24px;
}
.Search_search__JtbP4 svg {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 640px) {
  .Search_search__JtbP4 {
    margin-top: 16px;
    border-radius: 12px;
  }
  .Search_search_loading__ubTs\\+ {
    height: 64px;
  }
  .Search_search__JtbP4 input {
    height: 64px;
    font-size: 18px;
    letter-spacing: -0.36px;
    border-radius: 12px;
    padding-left: 32px;
  }
  .Search_search__JtbP4 svg {
    width: 32px;
    height: 32px;
    right: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Search/Search.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;AAAJ;AAKE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,2BAAA;AAHJ;AAME;EAlCF;IAmCI,gBAAA;IACA,mBAAA;EAHF;EAKE;IACE,YAAA;EAHJ;EAME;IACE,YAAA;IACA,eAAA;IACA,uBAAA;IACA,mBAAA;IACA,kBAAA;EAJJ;EAOE;IACE,WAAA;IACA,YAAA;IACA,WAAA;EALJ;AACF","sourcesContent":[".search {\n  margin-top: 8px;\n  line-height: 0;\n  position: relative;\n  border-radius: 8px;\n\n  &_loading {\n    width: 100%;\n    height: 30px;\n  }\n\n  input {\n    width: 100%;\n    padding-right: 26px;\n    padding-left: 16px;\n    height: 36px;\n    border-radius: 8px;\n    background: #EEE;\n    font-size: 14px;\n    line-height: 36px;\n    font-style: normal;\n    font-weight: 400;\n    letter-spacing: -0.24px;\n    // line-height: 100%\n\n  }\n\n  svg {\n    position: absolute;\n    right: 8px;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  @media (min-width: 640px) {\n    margin-top: 16px;\n    border-radius: 12px;\n\n    &_loading {\n      height: 64px;\n    }\n\n    input {\n      height: 64px;\n      font-size: 18px;\n      letter-spacing: -0.36px;\n      border-radius: 12px;\n      padding-left: 32px;\n    }\n\n    svg {\n      width: 32px;\n      height: 32px;\n      right: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Search_search__JtbP4`,
	"search_loading": `Search_search_loading__ubTs+`
};
export default ___CSS_LOADER_EXPORT___;
