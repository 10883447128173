import { getCookie } from "../utils/cookies"
import { USER_TOKEN_COOKIE } from "../utils/constants"
import { useCheckTokenMutation } from "../redux/api/auth"
import { useEffect } from "react"
import { useAppDispatch } from "../hooks"
import { authModalOpen } from "../redux/slice/authModal"
import { languageModalOpen } from "../redux/slice/languageModal"
import { getKeyLangMap, langWithCurrentMomentCode } from "../utils/helpers"
import { useTranslation } from "react-i18next"

const WithAuth = () => {
  const token = getCookie(USER_TOKEN_COOKIE)
  const [checkToken, test] = useCheckTokenMutation({
    fixedCacheKey: "authCacheKey",
  })
  const dispatch = useAppDispatch()
  const currentLang = localStorage.getItem("i18nextLng")
  const { i18n } = useTranslation()

  const userLang = navigator.language
  const keyLang = getKeyLangMap(userLang)

  useEffect(() => {
    if (token) {
      checkToken(token)
    } else {
      dispatch(authModalOpen({ flag: true }))
      if (!currentLang || currentLang === "dev" || !langWithCurrentMomentCode.get(currentLang)) {        
        setTimeout(()=>i18n.changeLanguage(keyLang ? keyLang[0] : "en"), 10)
        dispatch(languageModalOpen({ flag: true }))
      }
    }
  }, [])

  return <></>
}

export default WithAuth
